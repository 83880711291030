import React, { useState } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { InputSubmit, Error } from '../../ui/Formulario';
import firebase from '../../../firebase/firebase';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import Swal from 'sweetalert2';

//Validaciones

import useValidation from '../../../hooks/useValidation';
import validateCreateAccount from '../../../validation/validateCreateAccount';

const STATE_INICIAL = {
    name: '',
    phone: '',
    email: '',
    password: '',
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: '1rem',
        backgroundColor: 'var(--verde)',
        color: '#111111',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: '3rem',
    },
}));

export default function CreateAccount() {
    const history = useHistory();

    const [error, setError] = useState('');

    const { values, errors, handleSubmit, handleChange } = useValidation(
        STATE_INICIAL,
        validateCreateAccount,
        createNewAccount
    );
    const { email, password, name, phone } = values;

    const [privacyCheckbox, setPrivacyCheckbox] = useState(false);
    const handleChangeCheckbox = () => {
        setErrorCheckbox(false);
        setPrivacyCheckbox(!privacyCheckbox);
    };
    const [errorCheckbox, setErrorCheckbox] = useState(false);

    async function createNewAccount() {
        if (privacyCheckbox) {
            try {
                await firebase.register(email.trim().toLowerCase(), password, name, phone);
                Swal.fire(
                    'Tu cuenta ha sido creada',
                    'Bienvenido a RondaFit',
                    'success'
                );
                history.push('/miperfil');
            } catch (error) {
                setError('El correo electrónico ya está en uso');
            }
        } else {
            setErrorCheckbox(true);
        }
    }

    const classes = useStyles();

    return (
        <Container maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}></Avatar>
                <Typography component="h1" variant="h3">
                    Crear cuenta
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo electrónico"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        type="text"
                        value={email}
                        onChange={handleChange}
                        error={errors.email && true}
                        helperText={errors.email}
                        // inputProps={{ title: "Tu correo debe incluir @ y un dominio. Ej: .com, .es...", pattern: "^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$" }}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        value={password}
                        onChange={handleChange}
                        error={errors.password && true}
                        helperText={errors.password}
                        // inputProps={{ title: "Tu contraseña debe incluir más de 6 caracteres", pattern: ".{6,}" }}
                        autoComplete="current-password"
                    />
                    <TextField
                        variant="outlined"
                        required
                        margin="normal"
                        fullWidth
                        name="name"
                        label="Nombre"
                        type="text"
                        id="name"
                        value={name}
                        onChange={handleChange}
                        error={errors.name && true}
                        helperText={errors.name}
                    />
                    <TextField
                        variant="outlined"
                        required
                        margin="normal"
                        fullWidth
                        name="phone"
                        label="Teléfono"
                        type="number"
                        id="phone"
                        value={phone}
                        onChange={handleChange}
                        error={errors.phone && true}
                        helperText={errors.phone}
                    />

                    <FormControl required error={errorCheckbox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={privacyCheckbox}
                                    onChange={handleChangeCheckbox}
                                    name="Checkbox"
                                />
                            }
                            label={
                                <div>
                                    <span>He leído y acepto la </span>
                                    <Link
                                        style={{ textDecoration: 'underline' }}
                                        to="/politica-privacidad"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        política de privacidad.
                                    </Link>
                                </div>
                            }
                        />
                        {errorCheckbox && (
                            <FormHelperText>
                                Es obligatorio leer y aceptar la política de
                                privacidad
                            </FormHelperText>
                        )}
                    </FormControl>
                    <InputSubmit type="submit" value="Crear cuenta" />
                    {error && <Error className="my-4 px-2">{error}</Error>}

                    <div className="mt-2 text-center">
                        <Link to="/iniciar-sesion">
                            ¿Ya tienes cuenta? Iniciar sesión
                        </Link>
                    </div>
                </form>
            </div>
        </Container>
    );
}
