import styled from "@emotion/styled"

export const Formulario = styled.form`
    max-width:800px;
    width: 95%;
    margin: 2rem auto;
    label{
        margin: 0;
        font-size: 1rem;
    ;}
`;

export const Campo = styled.div`
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    label{
        flex: 0 0 150px;
        font-size: 1.8rem
    }
    input,textarea{
        flex:1;
    }
    textarea{
        height: 200px;
    }
`

export const InputSubmit = styled.input`
    background-color: var(--verde);
    margin-top: 1.5rem;
    width: 100%;
    padding: .5rem 0;
    text-align: center;
    color: #111111;
    font-size: 1.2rem;
    text-transform: uppercase;
    border-radius: 5px;
    font-weight: 500;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    &:hover {
        cursor: pointer;
        background-color: var(--verdeHover);
    }
`

export const Error = styled.p`
    background-color: tomato;
    padding: 0.4rem 0;
    font-weight: 500;
    color: #FFF;
    text-align: center;
    margin: 0.5rem 0;
    border-radius: 5px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
`;