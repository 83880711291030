import React from 'react';
import formatDistanceToNow from "date-fns/formatDistanceToNow"
import { es } from "date-fns/locale"
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        borderRadius: '0'
    },
    content: {
        padding: 0,
        paddingTop: '1rem',
        paddingBottom: '1rem'
    },
    textTime: {
        fontSize: '0.8rem'
    },
});

const VideoListing = ({ video, teacher }) => {

    const classes = useStyles();
    const videoName = video.name.replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, "-").toLowerCase()
    const teacherName = teacher.replace(/ /g, "-").toLowerCase()

    return (
        <Grid item xs={6} md={3}>
            <Card className={classes.root} elevation={0}>
                <Link to={`/clases/${teacherName}/${videoName}?v=${video.id}`} >
                    <div className="teacher-list-box">
                        <CardMedia
                            className="media-card-teacher"
                            image={video.imageUrl}
                            title={video.name}
                        />
                        <CardContent className={classes.content}>
                            <h6 className="text-uppercase">
                                {video.name}
                            </h6>
                            {/* <Typography gutterBottom className={classes.textTime}>
                                {video.teacher} hace: {formatDistanceToNow(new Date(video.createdAt), { locale: es })}
                            </Typography> */}
                        </CardContent>
                    </div>
                </Link>
            </Card>
        </Grid>
    );
}

export default VideoListing;