import React, { useState } from 'react';
import firebase from '../../../firebase/firebase';
import { InputSubmit, Error } from '../../ui/Formulario';

import TextField from '@material-ui/core/TextField';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

const ResetPassword = ({ setIsVisibleModal }) => {
    const [error, setError] = useState('');

    const ResetPasswordSubmit = async (e) => {
        e.preventDefault();
        try {
            await firebase.resetPassword(email.trim());
            Swal.fire(
                'Enlace envíado',
                'Abre tu correo electrónico, tal vez pueda llegar a la carpeta de SPAM, y sigue los pasos que te indicamos.',
                'success'
            );
            setIsVisibleModal(false);
        } catch (error) {
            setError('El correo electrónico no existe');
        }
    };

    const [email, setEmail] = useState('');
    const handleChange = (e) => {
        setEmail(e.target.value);
    };
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Recuperar contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={ResetPasswordSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo electrónico"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        type="text"
                        value={email}
                        onChange={handleChange}
                        // eslint-disable-next-line no-unused-vars
                        inputProps={{
                            title:
                                'Tu correo debe incluir @ y un dominio. Ej: .com, .es...',
                            pattern:
                                "^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$",
                        }}
                    />
                    <p className="text-center m-0">
                        Te enviaremos un correo electrónico con un link donde
                        podrás cambiar tu contraseña
                    </p>
                    <InputSubmit type="submit" value="Recuperar contraseña" />
                    {error && <Error>{error}</Error>}
                </form>
            </Modal.Body>
        </>
    );
};

export default ResetPassword;
