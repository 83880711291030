export default function validateCreateAccount(values) {
    let errors = {};

    //Validar el nombre de usuario

    // if (!values.name){
    //     errors.name= "El nombre es obligatorio";
    // }

    //Validar email
    if (!values.email) {
        errors.email = 'El Email es obligatorio';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email.trim())
    ) {
        errors.email =
            ' Correo electrónico no válido, debe incluir @ y un dominio Ej: example@example.com.';
    }

    if (!values.password) {
        errors.password = 'La contraseña es obligatoria';
    } else if (values.password.length < 6) {
        errors.password = 'La contraseña debe tener al menos 6 caracteres';
    }

    if (!values.name) {
        errors.name = 'El nombre es obligatorio';
    }

    if (!values.phone) {
        errors.phone = 'El teléfono es obligatorio';
    }

    return errors;
}
