import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FirebaseContext } from '../../../../context/Firebase.context';
import Error404 from '../../../layout/noticeUserToLogin';

import DashboardList from './dashboardList';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const Dashboard = () => {
    const { firebase, info } = useContext(FirebaseContext);
    const history = useHistory();

    // Declaraciones para traer los profesores desde la BBDD
    const [teachers, setTeachers] = useState([]);

    useEffect(() => {
        const getTeachers = () => {
            firebase.db
                .collection('teachers')
                .orderBy('createdAt', 'asc')
                .onSnapshot(handleSnapshot);
        };
        getTeachers();
        // eslint-disable-next-line
    }, []);
    // Total de Inscripciones
    const [inscriptionTotal, setInscriptionTotal] = useState(0);

    function handleSnapshot(snapshot) {
        const teachers = snapshot.docs.map((doc) => {
            return {
                id: doc.id,
                slug: doc.slug,
                ...doc.data(),
            };
        });
        setTeachers(teachers);
        let total = 0;
        teachers.map((elm) => (total += elm.inscriptions));
        setInscriptionTotal(total);
    }

    const deleteTeacher = (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No se podrá revertir esta acción!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, bórralo!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                firebase.db
                    .collection('teachers')
                    .doc(id)
                    .delete()
                    .then(function () {
                        Swal.fire(
                            'Borrado!',
                            'El profesor ha sido borrado',
                            'success'
                        );
                    })
                    .catch(function (error) {
                        Swal.fire(
                            'Hubo un error al borrar el profesor',
                            'Póngase en contacto con el administrador',
                            'error'
                        );
                    });
            }
        });
    };

    return (
        <Container className="my-5">
            {info.role !== 'admin' ? (
                <Error404 />
            ) : (
                <>
                    <h1 className="text-center">Panel de administración</h1>
                    <Grid container spacing={3} className="my-3">
                        <Grid item xs={6} sm={4}>
                            <Button
                                block
                                variant="outline-dark"
                                className="py-2 btn-cool"
                                onClick={() =>
                                    history.push('/admin/nuevo-profesional')
                                }
                            >
                                Nuevo grupo de videos
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Button
                                block
                                variant="outline-dark"
                                className="py-2"
                                onClick={() =>
                                    history.push('/admin/nuevo-video')
                                }
                            >
                                Subir Clase
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Button
                                block
                                variant="outline-dark"
                                className="py-2"
                                onClick={() =>
                                    history.push('/admin/nueva-pagina')
                                }
                            >
                                Nueva Página
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Button
                                block
                                variant="outline-dark"
                                className="py-2"
                                onClick={() => history.push('/miperfil')}
                            >
                                Mi perfil
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Button
                                block
                                variant="outline-dark"
                                className="py-2"
                                onClick={() =>
                                    history.push('/admin/lista-usuarios')
                                }
                            >
                                Lista de usuarios
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Button
                                block
                                variant="outline-dark"
                                className="py-2"
                                onClick={() =>
                                    history.push('/admin/opciones-suscripcion')
                                }
                            >
                                Configurar suscripciones
                            </Button>
                        </Grid>
                    </Grid>
                    {/* <Table responsive>
                        <thead>
                            <tr>
                                <th>Profesor</th>
                                <th className="text-center">Inscripciones</th>
                                <th>Orden</th>
                                <th>Categoría</th>
                                <th>Imagen</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {teachers.map((elm) => (
                                <DashboardList
                                    key={elm.id}
                                    {...elm}
                                    deleteTeacher={() => deleteTeacher(elm.id)}
                                />
                            ))}
                        </tbody>
                    </Table> */}
                    <h3>
                        Inscripciones totales (Antiguas): {inscriptionTotal}
                    </h3>
                </>
            )}
        </Container>
    );
};

export default Dashboard;
