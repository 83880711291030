import React, { Component } from 'react'

import Button from 'react-bootstrap/Button'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextSharpIcon from '@material-ui/icons/NavigateNextSharp';
import Boton from './Boton'
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


class ViewPDF extends Component {
    constructor(props) {
        super(props)
        this.state = {
            numPages: null,
            pageNumber: 1,
        }
    }
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    nextPage = () => {

        if (this.state.pageNumber < this.state.numPages) this.setState({ "pageNumber": this.state.pageNumber + 1 })
    }
    prevPage = () => {
        if (this.state.pageNumber > 1) this.setState({ "pageNumber": this.state.pageNumber - 1 })
    }


    render() {
        const { pageNumber, numPages } = this.state;
        return (
            <div className="d-flex flex-column align-items-center" >
                <Document
                    file={this.props.pdfUrl}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                >

                    <Page pageNumber={pageNumber} />
                </Document>
                <div className="page-buttons mt-2">
                    <Button variant="outline-dark" onClick={this.prevPage}><NavigateBeforeIcon />Anterior</Button>
                    <p className="text-center mb-3 mx-5">{pageNumber} de {numPages}</p>
                    <Button variant="outline-dark" onClick={this.nextPage}>Siguiente<NavigateNextSharpIcon /></Button>
                </div>

                <a href={this.props.pdfUrl} download={this.props.videoName} target="_blank" rel="noopener noreferrer">
                    <Boton bgColor='true'>Descargar pdf</Boton>
                </a>

            </div>
        );
    }
}

export default ViewPDF