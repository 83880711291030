import React, { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../../context/Firebase.context';
import VideoList from '../../components/layout/VideoList';
import EditTeacher from './admin/editar-profesional';
import NuevaPagina from './admin/nueva-pagina';
import Swal from 'sweetalert2';

import { useParams, useHistory } from 'react-router-dom';
import Error404 from '../layout/noticeUserToLogin';
import Spinner from '../ui/Spinner';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InstagramIcon from '@material-ui/icons/Instagram';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Button from 'react-bootstrap/Button';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function PaginaCategoria(props) {
    const { firebase, user, info } = useContext(FirebaseContext);
    const history = useHistory();

    const [pageInfo, setPageInfo] = useState(null);
    const [loading, setLoading] = useState(false);

    const params = useParams();
    const slug = params.slug;

    useEffect(() => {
        if (slug) {
            const fetchData = async () => {
                // const teacherData = await firebase.db.collection("teachers").doc(id)
                // const getInfo = await teacherData.get()
                // setTeacherInfo(getInfo.data())
                setLoading(true);
                const pageData = await firebase.db.collection('pages');
                const query = await pageData.where('slug', '==', slug);
                query.onSnapshot(handleSnapshot);
            };
            fetchData();
        }
        return () => {
            setPageInfo(null);
        };

        // eslint-disable-next-line
    }, [slug]);

    function handleSnapshot(snapshot) {
        const pages = snapshot.docs.map((doc) => {
            return {
                id: doc.id,
                ...doc.data(),
            };
        });
        setPageInfo(pages[0]);
        setLoading(false);
    }

    const [editBox, setEditBox] = useState(false);
    const openEditBox = () => {
        setEditBox(!editBox);
    };

    const deletePag = async () => {
        if (!user) {
            return history.push('/login');
        }

        if (user.role === 'admin') {
            return history.push('/');
        }

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Los cambios no pueden recuperarse',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borrar página',
        }).then((result) => {
            if (result.value) {
                firebase.db
                    .collection('pages')
                    .doc(pageInfo.id)
                    .delete()
                    .then((x) => {
                        Swal.fire('La página ha sido borrada', '', 'success');
                        history.push('/');
                    })
                    .catch((error) =>
                        Swal.fire(
                            'Hubo un error al borrar la página',
                            'Póngase en contacto con el administrador',
                            'error'
                        )
                    );
            }
        });
    };
    return (
        <>
            {loading && <Spinner />}
            {!loading && !pageInfo && <Error404 />}
            {pageInfo && (
                <>
                    <div
                        className="shadow"
                        style={{
                            backgroundImage: `url(${pageInfo.imageUrl[0]})`,
                            width: '100%',
                            height: '350px',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div
                            className="p-4 w-100 h-100 d-flex justify-content-center align-items-center text-uppercase"
                            style={{
                                borderRadius: '10px',
                                backgroundColor: '#10101087',
                            }}
                        >
                            <div className="d-flex flex-column align-items-center">
                                <div className="text-uppercase text-center">
                                    <h1 className="mb-1 text-white ">
                                        {pageInfo.title}
                                    </h1>
                                </div>
                                <div className="text-uppercase text-center">
                                    <h6 className="mb-1 text-white">
                                        <em>{pageInfo.subTitle}</em>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Container className="mt-4">
                        <Grid
                            container
                            style={{ maxWidth: '800px' }}
                            className="d-flex align-items-center justify-content-center mb-4 mx-auto"
                        >
                            <Grid
                                item
                                className="d-flex flex-column align-items-center justify-content-center mt-4"
                            >
                                {/* <img
                                    className="img-teacher-detail mb-1"
                                    src={pageInfo.imageUrl}
                                    alt={pageInfo.title}
                                /> */}

                                <div className="text-teacher-detail">
                                    {pageInfo.description && (
                                        <h5>{pageInfo.description}</h5>
                                    )}
                                    {pageInfo.description2 && (
                                        <p className="mb-5 mt-2">
                                            {pageInfo.description2}
                                        </p>
                                    )}
                                    {pageInfo.horario !== '' &&
                                        pageInfo.horario.length > 0 && (
                                            <h3
                                                className="text-center mb-0"
                                                style={{
                                                    textDecoration: 'underline',
                                                }}
                                            >
                                                Horarios
                                            </h3>
                                        )}
                                    {pageInfo.horario &&
                                        pageInfo.horario.length > 0 &&
                                        pageInfo.horario.map((elm, idx) => (
                                            <div key={elm + idx}>
                                                <p className="mb-1">{elm}</p>
                                            </div>
                                        ))}
                                    {pageInfo.price && (
                                        <div className="my-4">
                                            <h3
                                                className="text-center mb-0"
                                                style={{
                                                    textDecoration: 'underline',
                                                }}
                                            >
                                                Precio
                                            </h3>
                                            <p className="mb-1">
                                                {pageInfo.price}
                                            </p>
                                        </div>
                                    )}
                                    {pageInfo.instagram && (
                                        <a
                                            className="d-none d-sm-block"
                                            href={`https://instagram.com/${pageInfo.instagram}/`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <p className="text-uppercase">
                                                <InstagramIcon
                                                    style={{ fontSize: 30 }}
                                                />{' '}
                                                @{pageInfo.instagram}
                                            </p>
                                        </a>
                                    )}
                                </div>
                            </Grid>
                            <Swiper
                                className="w-100 mt-4"
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 0,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 2,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                    },
                                }}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                // onSlideChange={() =>
                                //     console.log('slide change')
                                // }
                                // onSwiper={(swiper) => console.log(swiper)}
                            >
                                {pageInfo.imageUrl.slice(1).map((img, idx) => (
                                    <SwiperSlide key={img}>
                                        <div className="d-flex justify-content-center">
                                            <img
                                                className="img-swiper-detail"
                                                src={img}
                                                alt={idx}
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Grid>
                        {info.role === 'admin' && (
                            <div className="d-flex justify-content-center">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={openEditBox}
                                            name="openEditBox"
                                        />
                                    }
                                    label="Editar profesional"
                                />
                                <Button
                                    variant="danger"
                                    className="my-2 px-5"
                                    onClick={deletePag}
                                >
                                    Eliminar página
                                </Button>
                            </div>
                        )}
                        <br />
                        {editBox && <NuevaPagina pageInfo={pageInfo} />}
                        <br />
                    </Container>
                </>
            )}
        </>
    );
}
