import styled from "@emotion/styled"

const Boton = styled.div`
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    border: 1px solid #d1d1d1;
    padding: .5rem 2rem;
    margin-top: 0.5rem;
    margin-right: 1rem;
    background-color: ${props => props.bgColor ? "#1DEA87" : "white"};
    color: ${props => props.bgColor ? "#111111" : "#000"};
    
    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        cursor: pointer;
    }
`;

export default Boton