const firebaseConfig = {
  apiKey: "AIzaSyCBXy5bKaGqyl3mP77faNLutWfXhzCvW-0",
  authDomain: "rondafit-cc659.firebaseapp.com",
  databaseURL: "https://rondafit-cc659.firebaseio.com",
  projectId: "rondafit-cc659",
  storageBucket: "rondafit-cc659.appspot.com",
  messagingSenderId: "842581439386",
  appId: "1:842581439386:web:7618e8cacec109d89d0023"
};

  export default firebaseConfig