import React, { useState, useEffect, useContext } from "react";

// import Router from "next/router" TO DO METER ROUTER DE REACT
import { Link } from "react-router-dom";
import { FirebaseContext } from "../../context/Firebase.context"
import PayPalPayment from "../layout/PaypalPayment"
import ActivateByCode from "./admin/activateByCode"

// Material UI
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from 'react-bootstrap/Button'
import FormHelperText from '@material-ui/core/FormHelperText';
import Swal from 'sweetalert2'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));


export default function CreateSubscription(props) {



  const classes = useStyles();
  const { user, firebase, subcriptionSettings, info, setInfo } = useContext(FirebaseContext);

  const [teachers, setTeachers] = useState([])
  const [teacherID, setTeacherId] = useState('')
  const [teacherInf, setTeacherInf] = useState(null)

  const [price, setPrice] = useState(null)
  const [validUntil, setValidUntil] = useState(null)
  const [dateLimit, setDateLimit] = useState(null)

  useEffect(() => {
    const getTeachers = () => {
      firebase.db.collection("teachers").orderBy("createdAt", "desc").onSnapshot(handleSnapshot)
    }
    getTeachers();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (subcriptionSettings) {
      setPrice(subcriptionSettings.price)
      setValidUntil(new Date(subcriptionSettings.validUntil))
      setDateLimit(subcriptionSettings.validUntil)
    }
    return () => {
      setPrice(null)
      setValidUntil(null)
      setDateLimit(null)
    }
  }, [subcriptionSettings])

  function handleSnapshot(snapshot) {
    const teachers = snapshot.docs.map(doc => {

      return {
        id: doc.id,
        ...doc.data()
      }
    });
    setTeachers(teachers)
  }

  const handleChangeSelect = async (e) => {
    setTeacherId(e.target.value)
    const teacherData = await firebase.db.collection("teachers").doc(e.target.value)
    const getInfo = await teacherData.get()
    setTeacherInf(getInfo.data())
    setError(false)
  }

  const [gift, setGift] = useState(false)
  const openEmailInput = () => {
    if (teacherID && privacyCheckbox) {
      setGift(!gift)
      if (paymentBox) setPaymentBox(!paymentBox)
    } else if (teacherID && !privacyCheckbox) {
      setError(false)
      setErrorCheckbox(true)
    } else if (!teacherID && privacyCheckbox) {
      setError(true)
      setErrorCheckbox(false)
    } else {
      setError(true)
      setErrorCheckbox(true)
    }

    if (activateByCode) openActivateByCode()
  };

  const [paymentBox, setPaymentBox] = useState(false)
  const openPayment = () => {
    if (teacherID && privacyCheckbox) {
      setPaymentBox(!paymentBox)
      if (gift) setGift(!gift)
    } else if (teacherID && !privacyCheckbox) {
      setError(false)
      setErrorCheckbox(true)
    } else if (!teacherID && privacyCheckbox) {
      setError(true)
      setErrorCheckbox(false)
    } else {
      setError(true)
      setErrorCheckbox(true)
    }
  }

  const [activateByCode, setActivateByCode] = useState(false)
  const openActivateByCode = () => {
    setActivateByCode(!activateByCode);
    if (gift) openEmailInput()

  };
  const [emailTest, setEmailTest] = useState(false)

  const [emailGift, setEmailGift] = useState('')
  const handleChange = e => {
    setEmailGift(e.target.value.toLowerCase())

  }
  const [emailGiftError, setEmailGiftError] = useState(null)

  const regex = /^[a-z0-9](?!.*?[^\na-z0-9]{2})[^\s@]+@[^\s@]+\.[^\s@]+[a-z0-9]$/
  const emailGiftValidation = () => {
    if (regex.test(emailGift.toLowerCase())) {
      setEmailTest(true)
      setEmailGiftError(null)
    } else {
      setEmailTest(false)
      setEmailGiftError('Introduce un correo electróncio válido. Debe incluir @ y un dominio.')
    }
  }

  function okRedirect(activeUntil) {
    if (activeUntil) {
      setInfo({ ...info, activeUntil: activeUntil, active: true, activationDate: Date.now() })
      props.history.push("/miperfil");
      Swal.fire(
        'Suscripción activada',
        '',
        'success'
      )
    } else {
      props.history.push("/miperfil");
    }

  }

  const [error, setError] = useState(false)

  const [privacyCheckbox, setPrivacyCheckbox] = useState(false)
  const handleChangeCheckbox = () => {
    setErrorCheckbox(false)
    setPrivacyCheckbox(!privacyCheckbox)
  }
  const [errorCheckbox, setErrorCheckbox] = useState(false)

  return (
    <>
      <Container maxWidth="sm" className="mb-5">
        <div className={classes.paper}>
          <h1>
            Únete a nosotros
          </h1>
          <img className="mb-2" src="/img/RF-Logo-negro.png" alt="RondaFit logo negro" />
          <p style={{ color: '#1DEA87', fontWeight: 'bold' }} className="my-3 teacher-list-box" onClick={openActivateByCode}>¿Tienes un código regalo? Haz clic aquí.</p>
          {activateByCode && <ActivateByCode />}
          <h3 className="text-center">Suscríbete por solo {price}€ y tendrás acceso ilimitado hasta el&nbsp;
            {validUntil && validUntil.getDate()}/
            {validUntil && validUntil.getMonth() + 1}/
            {validUntil && validUntil.getFullYear()}</h3>
          {!activateByCode && <>
            {!user ?
              <>
                <h4 className="text-center">Para suscribirte primero debes registrarte o iniciar sesión</h4>
                <Grid className="my-3" container spacing={2}>
                  <Grid item xs={6}>
                    <Link to="/iniciar-sesion"><Button block variant="dark">Iniciar Sesión</Button></Link>
                  </Grid>
                  <Grid item xs={6}>
                    <Link to="/crear-cuenta"><Button block variant="outline-dark" style={{ backgroundColor: "#1DEA87" }} onClick={openPayment}>Crear cuenta</Button></Link>
                  </Grid>
                </Grid>
              </>
              :
              <>
                <form className={classes.form}>
                  <FormControl style={{ minWidth: '100%' }} variant="outlined" error={error}>
                    <InputLabel id="demo-simple-select-outlined-label">¿Selecciona por quién nos has conocido?</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="teacher"
                      displayEmpty
                      required
                      value={teacherID}
                      onChange={handleChangeSelect}
                      label="¿Selecciona por quién nos has conocido?">
                      {teachers.map(elm => <MenuItem key={elm.id} value={elm.id}>{elm.name} - {elm.category.toUpperCase()}</MenuItem>)}
                    </Select>
                    {error && <FormHelperText>*Es obligatorio seleccionar un profesor</FormHelperText>}
                  </FormControl>
                  {/* <p>*Selecciona al profesor por el que nos has conocido</p> */}
                </form>
                <FormControl required error={errorCheckbox}>
                  <FormControlLabel control={<Checkbox
                    checked={privacyCheckbox}
                    onChange={handleChangeCheckbox}
                    name="Checkbox" />} label={<div>
                      <span>He leído y acepto la </span>
                      <Link style={{ textDecoration: 'underline' }} to='/politica-privacidad' target="_blank" rel="noopener noreferrer">política de privacidad.</Link>
                    </div>} />
                  {errorCheckbox && <FormHelperText>Es obligatorio leer y aceptar la política de privacidad</FormHelperText>}
                </FormControl>
                <Grid className="my-3" container spacing={2}>
                  <Grid item xs={6}>
                    <Button block variant="dark" onClick={openEmailInput}>Regalar suscripción</Button>
                  </Grid>
                  <Grid item xs={6}>
                    {!info.active && <Button block variant="outline-dark" style={{ backgroundColor: "#1DEA87" }} onClick={openPayment}>Pagar suscripción</Button>}
                  </Grid>
                </Grid>
              </>
            }
            {gift && (
              <>
                <h4 className="text-center mb-3">Introduce el correo electrónico del receptor del regalo</h4>
                <TextField
                  variant="outlined"
                  required
                  disabled={emailTest && true}
                  fullWidth
                  label="Correo electrónico"
                  name="email"
                  autoComplete="email"
                  type="text"
                  value={emailGift}
                  onChange={handleChange}
                  // inputProps={{ title: "Tu correo debe incluir @ y un dominio. Ej: .com, .es...", pattern: "^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$" }}
                  helperText={emailGiftError && emailGiftError}
                  error={emailGiftError && true}
                />
                <p className="my-2">*El correo electrónico introducido será con el que el receptor del regalo deberá registrarse en RondaFit antes de introducir el código de activación que te daremos después del pago. Podrá activar la cuenta desde esta misma página o en "mi perfil".</p>
                <Button block variant="outline-dark" style={{ backgroundColor: "#1DEA87" }} className="my-3" onClick={emailGiftValidation}>Proceder al pago</Button>
              </>
            )}



          </>}
        </div>

        {!activateByCode && <>
          {paymentBox || emailTest ? <div className="my-4">
            <h4 className="text-center mb-3">Elige el método de pago</h4>
            {!gift ? <PayPalPayment user={user} firebase={firebase} teacherRef={teacherID} totalRefs={teacherInf} done={okRedirect} price={price} activeUntil={dateLimit} />
              : <PayPalPayment user={user} firebase={firebase} teacherRef={teacherID} totalRefs={teacherInf} emailGift={emailGift} done={okRedirect} price={price} activeUntil={dateLimit} />}

          </div> : null}
        </>}
      </Container>
    </>
  );
}
