
import React, { createContext, useState, useEffect } from 'react';
import firebase from "../firebase/firebase"
import authenticateUser from "../hooks/useAuthentication"


export const FirebaseContext = createContext()


const FirebaseProvider = props => {

    const user = authenticateUser()

    const [info, setInfo] = useState({})
    const [subcriptionSettings, setSubcriptionSettings] = useState(null)

    useEffect(() => {
        const getUserInfo = async () => {
            const userInfo = await firebase.db.collection("users").doc(user.uid)
            const getInfo = await userInfo.get()
            setInfo(getInfo.data())
        }
        if (user) getUserInfo();

        const getSettings = async () => {
            const suscriptionsSettings = await firebase.db.collection("settings").doc("options")
            const settings = await suscriptionsSettings.get();

            if (settings.exists) {
                setSubcriptionSettings(settings.data());
            }
        }
        getSettings();
        
    }, [user, info])
    

    return (

        <FirebaseContext.Provider
            value={{
                firebase,
                user,
                info,
                subcriptionSettings,
                setInfo,
                setSubcriptionSettings


            }}
        >
            {props.children}

        </FirebaseContext.Provider>

    )
}

export default FirebaseProvider