export default function validateCreateAccount(values) {
    let errors = {};

    if (!values.title) {
        errors.title = 'El titulo es obligatorio';
    }

    if (!values.description) {
        errors.description = 'La descripción es obligatoria';
    }

    return errors;
}
