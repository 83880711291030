import React from 'react';
import { Link } from 'react-router-dom'
import Container from "@material-ui/core/Container"

export default function NoMatch() {
  return (
    <Container className="my-5">
      <h1 className="my-5 text-center"
      >404 - La página no existe :( </h1>
      <Link to="/" ><h2 className="text-center">Volver a Inicio</h2></Link>
      <div className="d-flex justify-content-center">
        <img src="/img/RF-Logo-verde.png" alt="RondaFit logo verde" />
      </div>
    </Container>

  );
};