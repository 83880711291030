import React,{useEffect, useState} from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import FirebaseProvider from "./context/Firebase.context"
import CookieConsent from "react-cookie-consent";
import Login from "./components/pages/auth/iniciar-sesion"
import Home from "./components/pages/home"
import Perfil from "./components/pages/miperfil"
import Suscripcion from "./components/pages/suscripcion"
import Dashboard from "./components/pages/admin/dashboard/dashboard"
import SubirVideo from "./components/pages/admin/nuevo-video"
import NuevoProfesional from "./components/pages/admin/nuevo-profesional"
import CrearCuenta from "./components/pages/auth/crear-cuenta"
import ListadoClases from "./components/pages/clases"
import DetallesProfesor from "./components/pages/detallesProfesor"
import DetallesVideo from "./components/pages/detallesVideo"
import CuponesRegalo from "./components/pages/admin/dashboard/cupones-regalo"
import UserList from "./components/pages/admin/dashboard/userList"
import PoliticaPrivacidad from "./components/pages/privacy/politica-privacidad"
import PoliticaCookies from "./components/pages/privacy/politica-cookies"
import AvisoLegal from "./components/pages/privacy/aviso-legal"
import OpcionesSuscripcion from "./components/pages/admin/dashboard/opciones-suscripcion"
import authenticateUser from "./hooks/useAuthentication"
import firebase from "./firebase/firebase"
import NoMatch from "./components/layout/NotFound"
import Layout from "./components/layout/Layout"
import PaginaCategoria from './components/pages/PaginaCategoria';
import NuevaPagina from './components/pages/admin/nueva-pagina';

export default function App() {

  const userLogged = authenticateUser()
  
  const [info, setInfo] = useState({})

  useEffect(() => {
    const getUserInfo = async () => {
        const userInfo = await firebase.db.collection("users").doc(userLogged.uid)
        const getInfo = await userInfo.get()
        setInfo(getInfo.data())
      }
      if (userLogged) getUserInfo();
      
    }, [userLogged])
   
    
    async function SuscripcionOut(){
      
      await firebase.db.collection("users").doc(userLogged.uid).update({
        'active': false
      })
       return firebase.auth.signOut()

    }
    
    if(info && userLogged && info.active) {
      if(info.activeUntil > Date.now()){
        
      }else {
        SuscripcionOut()
      }
    }
   

  return (
    <>

      <FirebaseProvider>
        <Layout>
          <Switch>
            <Route exact path="/" render={() => <Home />} />
            <Route path="/iniciar-sesion" render={() => <Login />} />
            <Route path="/crear-cuenta" render={() => <CrearCuenta />} />
            <Route path="/miperfil" render={() => <Perfil />} />
            {/* <Route path="/suscripcion" render={props => <Suscripcion {...props}/>} /> */}

            <Route exact path="/clases" render={() => <ListadoClases />} />
            <Route exact path="/clases/:profesor" render={props => <DetallesProfesor {...props} />} />
            <Route exact path="/clases/:profesor/:video" render={props => <DetallesVideo {...props} />} />
            
            <Route exact path="/pagina/:slug" render={() => <PaginaCategoria /> } />

            <Route exact path="/panel-administracion" render={() => info.role === "admin" ? <Dashboard /> : <Redirect to="/" /> } />
            <Route path="/admin/nuevo-video" render={() => info.role === "admin" ? <SubirVideo/> : <Redirect to="/" />} />
            <Route path="/admin/nuevo-profesional" render={() => info.role === "admin" ? <NuevoProfesional/> : <Redirect to="/"/> } />
            <Route path="/admin/nueva-pagina" render={() => info.role === "admin" ? <NuevaPagina/> : <Redirect to="/"/> } />
            
            <Route path="/admin/cupones-regalo" render={() =>  info.role === "admin" ? <CuponesRegalo /> : <Redirect to="/"/>} />
            <Route path="/admin/lista-usuarios" render={() =>  info.role === "admin" ? <UserList /> : <Redirect to="/" />  } />
            <Route path="/admin/opciones-suscripcion" render={props => info.role === "admin" ? <OpcionesSuscripcion {...props} />  : <Redirect to="/"/>} />

            <Route exact path="/politica-privacidad" render={() => <PoliticaPrivacidad />} />
            <Route exact path="/politica-cookies" render={() => <PoliticaCookies />} />
            <Route exact path="/aviso-legal" render={() => <AvisoLegal />} />

            <Route component={NoMatch} />
          </Switch>
        </Layout>

      </FirebaseProvider>
      <CookieConsent
      // debug={true}
  location="bottom"
  buttonText="Entendido"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px",fontWeight: "bold" }}
  expires={150}
>
  <span>Utilizamos cookies propias y de terceros para obtener datos estadísticos de la navegación de nuestros usuarios y mejorar nuestros servicios.
  {" "}Si acepta o continúa navegando, consideramos que acepta su uso.</span>{" "}
</CookieConsent>
    </>

  )
}