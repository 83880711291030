import React, { useState, useContext } from 'react'
import { FirebaseContext } from "../../../context/Firebase.context"

import { InputSubmit, Error } from "../../../components/ui/Formulario"
import TextField from '@material-ui/core/TextField';
import Modal from 'react-bootstrap/Modal'

import useValidation from "../../../hooks/useValidation"
import validatePassword from "../../../validation/validatePassword"
import Swal from 'sweetalert2'

export default function ChangePasswordForm(props) {
    let { firebase } = useContext(FirebaseContext)

    const { setIsVisibleModal } = props

    const STATE_INICIAL = {
        password: "",
        newPassword: "",
        newPasswordRepeat: "",
    }

    const [error, setError] = useState('')

    const { values, handleSubmit, handleChange } = useValidation(STATE_INICIAL, validatePassword, updatePassword);

    const { password, newPassword, newPasswordRepeat } = values

    function updatePassword() {
        setError('')
            if (newPassword !== newPasswordRepeat) {
                setError("Las contraseñas deben ser iguales")
            } else {
                firebase.reauthenticate(password)
                    .then(async function () {

                        try {
                            await firebase.updatePassword(newPassword)
                            Swal.fire(
                                'Tu contraseña ha sido modificada',
                                'Te recomendamos guardarla en un lugar seguro',
                                'success'
                            )
                            setIsVisibleModal(false)
                        } catch (error) {
                            setError("Error al actualizar contraseña")
                        }
                    })
                    .catch(function (error) {
                        setError("Contraseña incorrecta, pruebe otra vez")
                    });
            
        }

    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    Cambiar contraseña
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="modal-box">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Contraseña antigua"
                            name="password"
                            autoComplete="password"
                            autoFocus
                            type="password"
                            onChange={handleChange}
                            inputProps={{ title: "Tu contraseña debe incluir más de 6 caracteres", pattern: ".{6,}" }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="newPassword"
                            label="Nueva contraseña"
                            type="password"
                            id="newPassword"
                            onChange={handleChange}
                            inputProps={{ title: "Tu contraseña debe incluir más de 6 caracteres", pattern: ".{6,}" }}
                            autoComplete="current-password" />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="newPasswordRepeat"
                            label="Confirma tu contraseña"
                            type="password"
                            id="newPasswordRepeat"
                            onChange={handleChange}
                            inputProps={{ title: "Tu contraseña debe incluir más de 6 caracteres", pattern: ".{6,}" }}
                            autoComplete="current-password" />

                        {error && <Error>{error}</Error>}
                        <InputSubmit
                            type="submit"
                            value="Guardar"
                        />
                    </div>
                </form>
            </Modal.Body>
        </>

    )
}