import React, { useState, useContext } from 'react';
// import Router from "next/router"
import { FirebaseContext } from '../../../context/Firebase.context';
import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import { InputSubmit, Error } from '../../../components/ui/Formulario';

import Modal from 'react-bootstrap/Modal';

import useValidation from '../../../hooks/useValidation';
import validateLogin from '../../../validation/validateLogin';
import Swal from 'sweetalert2';

export default function ChangeEmailForm(props) {
    let { firebase, user, info, setInfo, setUser } = useContext(FirebaseContext);
    const history = useHistory();

    const { setIsVisibleModal } = props;
    const STATE_INICIAL = {
        email: user.email,
        name: info.name,
        phone: info.phone,
        password: '',
    };
    const [error, setError] = useState('');

    const { values, handleSubmit, handleChange } = useValidation(
        STATE_INICIAL,
        validateLogin,
        UpdateEmail
    );

    const { email, password, name, phone } = values;

    async function UpdateEmail() {

        if (name === '' || phone === '') {
            setError('No puede haber campos vacios');
            return;
        }
        setError('');
        try {
            await firebase.reauthenticate(password)
            
        } catch (error) {
            setError('Contraseña incorrecta, pruebe otra vez');
        }
        try {
            await firebase.updateEmail(email);
            await firebase.db.collection('users').doc(user.uid).update({
                email,
                name,
                phone,
            });
            Swal.fire(
                'Tu correo electrónico ha sido modificado',
                '',
                'success'
            );
            setUser({ ...user, email: email });
            setInfo({ ...info, name, phone });
            
        } catch (error) {
            setError('Error al actualizar correo electrónico');
        }finally{
            setIsVisibleModal(false);   
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Cambiar correo electrónico</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="modal-box">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Correo electrónico"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            type="text"
                            value={email && email}
                            onChange={handleChange}
                            // eslint-disable-next-line no-unused-vars
                            inputProps={{
                                title: 'Tu correo debe incluir @ y un dominio. Ej: .com, .es...',
                                pattern:
                                    "^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$",
                            }}
                        />

                        <TextField
                            variant="outlined"
                            required
                            margin="normal"
                            fullWidth
                            name="name"
                            label="Nombre"
                            type="text"
                            id="name"
                            value={name}
                            onChange={handleChange}
                        />
                        <TextField
                            variant="outlined"
                            required
                            margin="normal"
                            fullWidth
                            name="phone"
                            label="Teléfono"
                            type="number"
                            id="phone"
                            value={phone}
                            onChange={handleChange}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Confirma tu contraseña"
                            type="password"
                            id="password"
                            value={password}
                            onChange={handleChange}
                            inputProps={{
                                title: 'Tu contraseña debe incluir más de 6 caracteres',
                                pattern: '.{6,}',
                            }}
                            autoComplete="current-password"
                        />
                        <InputSubmit type="submit" value="Guardar" />
                        {error && <Error className="mt-3 pb-2">{error}</Error>}
                    </div>
                </form>
            </Modal.Body>
        </>
    );
}
