import React from 'react'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'


const Footer = () => {
    return (
        <footer>
            <Container>
                <ul style={{ color: 'white', display: "flex", justifyContent: "center" }}>
                    <li style={{}}><Link to="/politica-privacidad" >Política de Privacidad</Link></li>
                    <li style={{ marginLeft: 10 }}><Link to="/politica-cookies" >Política de Cookies</Link></li>
                    <li style={{ marginLeft: 10 }}><Link to="/aviso-legal" >Aviso Legal</Link></li>
                </ul>
            </Container>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <img className="logo-footer" src="/img/RF-Logo-verde.png" alt="RondaFit logo verde" />

                <small className="d-flex justify-content-center">
                    {'Copyright © '}{new Date().getFullYear()}
                    {'.'}
                </small>
            </div>
        </footer>
    );
}

export default Footer;