import React, { Component } from 'react';
import { FirebaseContext } from '../../context/Firebase.context';
import { PayPalButton } from 'react-paypal-button-v2';
import Swal from 'sweetalert2';
var generator = require('generate-password');

// import Router from "next/router" TO DO METER ROUTER DE REACT para hacer push y recuperar params

export default class PayPalPayment extends Component {
    static contextType = FirebaseContext;

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        // this.state = {
        //     settings: null
        // }
    }

    // componentDidUpdate() {

    //     const { subcriptionSettings } = this.context
    //     if (subcriptionSettings && !this.state.settings) this.setState({ settings: subcriptionSettings })

    // }

    render() {
        const password = generator.generate({
            length: 10,
            numbers: true,
            excludeSimilarCharacters: true,
            symbols: true,
        });

        if (this.props.emailGift) {
            return (
                <PayPalButton
                    amount={this.props.price && this.props.price}
                    // amount="60"
                    currency="EUR"
                    shippingPreference="NO_SHIPPING"
                    onError={(error) => console.log(error)}
                    catchError={(error) => console.log(error)}
                    onCancel={(error) => console.log(error)}
                    onSuccess={(details) => {
                        // console.log(details)
                        // console.log(this.props)
                        // async function createNewPayment() {
                        // }
                        try {
                            const registerPay = {
                                uid: this.props.user.uid,
                                name: details.payer.name.given_name,
                                email: details.payer.email_address,
                                referred: this.props.teacherRef,
                                type: 'gift',
                                allDetails: details,
                                createdAt: Date.now(),
                            };

                            const presentDetails = {
                                uid: this.props.user.uid,
                                emailDest: this.props.emailGift,
                                code: password,
                                activeUntil: this.props.activeUntil,
                                used: false,
                                createdAt: Date.now(),
                            };

                            this.props.firebase.db
                                .collection('payments')
                                .add(registerPay);
                            this.props.firebase.db
                                .collection('presents')
                                .add(presentDetails);

                            this.props.firebase.db
                                .collection('teachers')
                                .doc(this.props.teacherRef)
                                .update({
                                    inscriptions:
                                        this.props.totalRefs.inscriptions + 1,
                                });

                            Swal.fire(
                                `El codigo regalo es: ${password}`,
                                'Este código es el que tendrá que introducir la persona una vez registrada con el email que has introducido. Copialo y enviaselo!',
                                'success'
                            );
                            // history.push('/miperfil') // TO DO: CAMBIAR POR PUSH REACT
                            this.props.done();
                        } catch (error) {
                            console.error(
                                'Error al crear usuario',
                                error.message
                            );
                        }
                    }}
                    options={{
                        clientId:
                            'Aac4YYODLFC9WO2tbA71xz85blVbNnzubj0HaA0VFaag39qzPv2iVrI6YoweFxwg3G1SRHoblF8KGcaw',
                        currency: 'EUR',
                    }}
                />
            );
        } else {
            return (
                <PayPalButton
                    amount={this.props.price && this.props.price}
                    // amount="60"
                    currency="EUR"
                    onError={(error) => console.log(error)}
                    catchError={(error) => console.log(error)}
                    onCancel={(error) => console.log(error)}
                    shippingPreference="NO_SHIPPING"
                    onSuccess={(details) => {
                        // console.log(details)
                        // console.log(this.props)
                        // async function createNewPayment() {
                        // }
                        try {
                            const registerPay = {
                                uid: this.props.user.uid,
                                name: details.payer.name.given_name,
                                email: details.payer.email_address,
                                referred: this.props.teacherRef,
                                type: 'normal',
                                createdAt: Date.now(),
                                allDetails: details,
                            };

                            this.props.firebase.db
                                .collection('payments')
                                .add(registerPay);
                            this.props.firebase.db
                                .collection('users')
                                .doc(this.props.user.uid)
                                .update({
                                    active: true,
                                    activationDate: Date.now(),
                                    activeUntil: this.props.activeUntil,
                                });

                            this.props.firebase.db
                                .collection('teachers')
                                .doc(this.props.teacherRef)
                                .update({
                                    inscriptions:
                                        this.props.totalRefs.inscriptions + 1,
                                });
                        } catch (error) {
                            console.error(
                                'Error al crear usuario',
                                error.message
                            );
                        }
                        this.props.done(this.props.activeUntil);
                    }}
                    options={{
                        clientId:
                            'Aac4YYODLFC9WO2tbA71xz85blVbNnzubj0HaA0VFaag39qzPv2iVrI6YoweFxwg3G1SRHoblF8KGcaw',
                        currency: 'EUR',
                    }}
                />
            );
        }
    }
}
