import React, { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../../context/Firebase.context';
import VideoList from '../../components/layout/VideoList';
import EditTeacher from './admin/editar-profesional';
import { useHistory } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import Error404 from '../layout/noticeUserToLogin';
import Spinner from '../ui/Spinner';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InstagramIcon from '@material-ui/icons/Instagram';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Pagination from './admin/dashboard/Pagination';

export default function TeacherDetails(props) {
    const { firebase, info } = useContext(FirebaseContext);
    const history = useHistory();

    const [videos, setVideos] = useState([]);
    const [teacherInfo, setTeacherInfo] = useState(null);
    const params = useParams();
    const slugTeacher = params.profesor;

    useEffect(() => {
        if (slugTeacher) {
            const getVideos = async () => {
                const videosFiltrados = await firebase.db.collection('videos');
                const query = await videosFiltrados
                    .where('teacherID', '==', slugTeacher)
                    .orderBy('createdAt', 'desc');

                query.onSnapshot(handleSnapshot);

                // const teacherData = await firebase.db.collection("teachers").doc(id)
                // const getInfo = await teacherData.get()
                // setTeacherInfo(getInfo.data())

                const teacherData = await firebase.db.collection('weeks');
                const query2 = await teacherData.where(
                    'slug',
                    '==',
                    slugTeacher
                );
                query2.onSnapshot(handleSnapshotTeacher);
            };
            getVideos();
        }
        return () => {
            setVideos([]);
            setTeacherInfo(null);
        };

        // eslint-disable-next-line
    }, [slugTeacher]);

    function handleSnapshot(snapshot) {
        const videos = snapshot.docs.map((doc) => {
            return {
                id: doc.id,
                ...doc.data(),
            };
        });
        setVideos(videos);
    }
    function handleSnapshotTeacher(snapshot) {
        const teachers = snapshot.docs.map((doc) => {
            return {
                id: doc.id,
                ...doc.data(),
            };
        });
        setTeacherInfo(teachers[0]);
    }

    const [editBox, setEditBox] = useState(false);
    const openEditBox = () => {
        setEditBox(!editBox);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(8);
    const [data, setData] = useState([]);

    const fetchContentTable = (contentSort) => {
        const offset = (currentPage - 1) * perPage;
        const contentPrev = contentSort ? contentSort : videos;
        const res = contentPrev.slice(offset, offset + +perPage);
        setData(res);
    };
    useEffect(() => {
        if (videos.length > 0) fetchContentTable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, perPage, videos]);

    const deleteWeek = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No se podrá revertir esta acción!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, bórralo!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                firebase.db
                    .collection('weeks')
                    .doc(teacherInfo.id)
                    .delete()
                    .then(function () {
                        Swal.fire(
                            'Borrado!',
                            'El usuario ha sido borrado',
                            'success'
                        );
                        history.push('/clases');
                    })
                    .catch(function (error) {
                        Swal.fire(
                            'Hubo un error al borrar el profesor',
                            'Póngase en contacto con el administrador',
                            'error'
                        );
                    });
            }
        });
    };

    return (
        <>
            {!teacherInfo ? (
                <Spinner />
            ) : (
                <>
                    {!info.active ? (
                        <Error404 />
                    ) : (
                        <Container className="my-4">
                            <Grid
                                container
                                style={{ maxWidth: '800px' }}
                                className="d-flex align-items-center justify-content-center mb-4 mx-auto"
                            >
                                <Grid
                                    item
                                    className="d-flex flex-column align-items-center justify-content-center my-2"
                                >
                                    {/* <img
                                        className="img-teacher-detail mb-1"
                                        src={teacherInfo.imageUrl}
                                        alt={teacherInfo.name}
                                    /> */}
                                    {teacherInfo.instagram && (
                                        <a
                                            className="d-block d-sm-none"
                                            href={`https://instagram.com/${teacherInfo.instagram}/`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <p className="text-uppercase mb-1">
                                                <InstagramIcon
                                                    style={{ fontSize: 25 }}
                                                />{' '}
                                                @{teacherInfo.instagram}
                                            </p>
                                        </a>
                                    )}
                                    <div className="text-teacher-detail">
                                        <div className="text-uppercase text-center">
                                            <h1 className="mb-1">
                                                {teacherInfo.name}
                                            </h1>
                                        </div>
                                        {teacherInfo.description && (
                                            <h4>{teacherInfo.description}</h4>
                                        )}
                                        {teacherInfo.instagram && (
                                            <a
                                                className="d-none d-sm-block"
                                                href={`https://instagram.com/${teacherInfo.instagram}/`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <p className="text-uppercase">
                                                    <InstagramIcon
                                                        style={{ fontSize: 30 }}
                                                    />{' '}
                                                    @{teacherInfo.instagram}
                                                </p>
                                            </a>
                                        )}
                                        {info.role === 'admin' && (
                                            <div className="d-flex align-content-center">
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            onChange={
                                                                openEditBox
                                                            }
                                                            name="openEditBox"
                                                        />
                                                    }
                                                    label="Editar profesional"
                                                />
                                                <Button
                                                    variant="outline-danger"
                                                    className="ml-2 p-2"
                                                    onClick={() => deleteWeek()}
                                                >
                                                    <DeleteForeverIcon fontSize="small" />
                                                    Borrar
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            {editBox && (
                                <EditTeacher
                                    id={teacherInfo.id}
                                    name1={teacherInfo.name}
                                    category1={teacherInfo.category}
                                    description1={teacherInfo.description}
                                    instagram1={teacherInfo.instagram}
                                    imageUrl1={teacherInfo.imageUrl}
                                    order1={teacherInfo.order}
                                />
                            )}

                            <Grid container spacing={4}>
                                {videos.length !== 0 ? (
                                    data.map((video) => (
                                        <VideoList
                                            key={video.id}
                                            video={video}
                                            teacher={teacherInfo.name}
                                        />
                                    ))
                                ) : (
                                    <h6>No hay videos disponibles</h6>
                                )}
                            </Grid>
                        </Container>
                    )}
                    {videos.length > 0 && (
                        <div className="mx-4">
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                                contentLength={videos.length}
                                optionsProp={[8, 12, 16, 20]}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
}
