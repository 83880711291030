import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../../../context/Firebase.context';

import Container from '@material-ui/core/Container';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';

import UserListTable from './UserListTable';
import Pagination from './Pagination';

const UserList = () => {
    const { firebase, subcriptionSettings } = useContext(FirebaseContext);

    // Declaraciones para traer los profesores desde la BBDD
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState(false);

    const [usersList, setUsersList] = useState([]);

    useEffect(() => {
        firebase.db
            .collection('users')
            .orderBy('createdAt', 'asc')
            .onSnapshot(handleSnapshot);
        // eslint-disable-next-line
    }, []);

    function handleSnapshot(snapshot) {
        const users = snapshot.docs.map((doc) => {
            return {
                id: doc.id,
                slug: doc.slug,
                ...doc.data(),
            };
        });
        setUsers(users);
        setUsersList(users);
    }

    const deleteSuscription = (id, active) => {
        Swal.fire({
            title: '¿Estás seguro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                if (active) {
                    firebase.db
                        .collection('users')
                        .doc(id)
                        .update({
                            active: !active,
                            activeUntil: 0,
                        })
                        .then(function () {
                            Swal.fire('Suscripción cambiada', '', 'success');
                        })
                        .catch(function (error) {
                            Swal.fire(
                                'Hubo un error',
                                'Póngase en contacto con el administrador',
                                'error'
                            );
                        });
                } else {
                    firebase.db
                        .collection('users')
                        .doc(id)
                        .update({
                            active: !active,
                            activationDate: Date.now(),
                            activeUntil: subcriptionSettings.validUntil,
                        })
                        .then(function () {
                            Swal.fire('Suscripción cambiada', '', 'success');
                        })
                        .catch(function (error) {
                            Swal.fire(
                                'Hubo un error',
                                'Póngase en contacto con el administrador',
                                'error'
                            );
                        });
                }
            }
        });
    };
    // Buscador de usuarios
    const [userSearched, setUserSearched] = useState('');
    const handleChangeSearch = (e) => setUserSearched(e.target.value);

    useEffect(() => {
        const busqueda = userSearched.toLowerCase();
        const filtro = usersList.filter((user) =>
            user.email.toLowerCase().includes(busqueda)
        );
        setUsers(filtro);
        // eslint-disable-next-line
    }, [userSearched]);

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [data, setData] = useState([]);

    const fetchContentTable = (contentSort) => {
        const offset = (currentPage - 1) * perPage;
        const contentPrev = contentSort ? contentSort : users;
        const res = contentPrev.slice(offset, offset + perPage);
        setData(res);
    };
    useEffect(() => {
        fetchContentTable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, perPage, users]);

    const deleteUser = (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No se podrá revertir esta acción!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, bórralo!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                firebase.db
                    .collection('users')
                    .doc(id)
                    .delete()
                    .then(function () {
                        Swal.fire(
                            'Borrado!',
                            'El usuario ha sido borrado',
                            'success'
                        );
                    })
                    .catch(function (error) {
                        Swal.fire(
                            'Hubo un error al borrar el profesor',
                            'Póngase en contacto con el administrador',
                            'error'
                        );
                    });
            }
        });
    };

    return (
        <Container className="my-5" maxWidth="md">
            <h1 className="text-center">lista de usuarios</h1>
            <input
                className="form-control my-2"
                value={userSearched}
                type="search"
                name="search"
                placeholder="Buscar usuario por su correo electrónico"
                aria-label="Search"
                id="index-input"
                onChange={handleChangeSearch}
            />
            <div className="d-flex justify-content-center">
                <Button
                    variant="info"
                    className="my-2 px-5"
                    onClick={() => setAllUsers(!allUsers)}
                >
                    Ver todos los emails para copiar
                </Button>
            </div>
            {allUsers &&
                users.map((user) => <div key={user.id}>{user.email}</div>)}
            <Table responsive>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Nombre</th>
                        <th>Teléfono</th>
                        <th style={{ minWidth: 120 }}>Activado</th>
                        <th className="text-center">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((user) => (
                        <UserListTable
                            key={user.id}
                            {...user}
                            deleteSuscription={() =>
                                deleteSuscription(user.id, user.active)
                            }
                            deleteUser={() => deleteUser(user.id)}
                        />
                    ))}
                </tbody>
            </Table>

            {users.length > 0 && (
                <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    contentLength={users.length}
                />
            )}
        </Container>
    );
};

export default UserList;
