import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../context/Firebase.context';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';

import Card from '../card';
import Spinner from '../ui/Spinner';
import InstagramIcon from '@material-ui/icons/Instagram';
import Button from 'react-bootstrap/Button';

// import Grid from '@material-ui/core/Grid';
// import SucriptionCard from '../ui/SuscriptionCard';
// import TeacherBar from '../layout/TeacherBar';

export default function Home() {
    const { firebase } = useContext(FirebaseContext);
    const history = useHistory();

    const [pageInfo, setPageInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openVideo, setOpenVideo] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const pageData = await firebase.db.collection('pages');
            pageData.onSnapshot(handleSnapshot);
        };
        fetchData();

        return () => {
            setPageInfo(null);
        };

        // eslint-disable-next-line
    }, []);

    function handleSnapshot(snapshot) {
        let pages = snapshot.docs.map((doc) => {
            return {
                id: doc.id,
                ...doc.data(),
            };
        });
        pages = pages.sort((a, b) => a.order - b.order);
        setPageInfo(pages);
        setLoading(false);
    }

    return (
        <>
            <aside className="aside-home">
                <div className="row">
                    <div className="col-12 d-md-none mb-2">
                        <div className="d-flex justify-content-center align-items-center">
                            <div
                                className="d-flex flex-column justify-content-center shadow rounded p-3 font-weight-bold"
                                style={{
                                    backgroundColor: '#000',
                                    color: '#1DEA87',
                                    maxWidth: '350px',
                                }}
                            >
                                <h6 className="text-uppercase m-0 font-weight-bold">
                                    Javi Ronda
                                </h6>
                                <h6 className="text-uppercase m-0 font-weight-bold">
                                    Tlf: 693 012 063
                                </h6>
                                <h6 className="text-uppercase m-0 font-weight-bold">
                                    rondafitapp@gmail.com
                                </h6>
                                <a
                                    href={`https://instagram.com/javilinmadrid/`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <p className="text-uppercase mb-0 text-light">
                                        <InstagramIcon
                                            style={{ fontSize: 30 }}
                                        />{' '}
                                        @javilinmadrid
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 d-flex align-content-center justify-content-center">
                        <Button
                            style={{
                                backgroundColor: '#1DEA87',
                                borderColor: '#1DEA87',
                                color: '#000',
                            }}
                            className="my-2 mx-md-5 shadow pointer"
                            onClick={() => history.push('/crear-cuenta')}
                        >
                            Regístrate y recibe información
                        </Button>
                    </div>
                    <div className="col-4 d-none d-md-block">
                        <div className="d-flex justify-content-center align-items-center">
                            <div
                                className="d-flex flex-column justify-content-center shadow rounded p-3"
                                style={{
                                    backgroundColor: '#000',
                                    color: '#1DEA87',
                                    maxWidth: '350px',
                                }}
                            >
                                <h6 className="text-uppercase m-0 font-weight-bold">
                                    Javi Ronda
                                </h6>
                                <h6 className="text-uppercase m-0 font-weight-bold">
                                    Tlf: 693 012 063
                                </h6>
                                <h6 className="text-uppercase m-0 font-weight-bold">
                                    rondafitapp@gmail.com
                                </h6>
                                <a
                                    className="d-none d-sm-block"
                                    href={`https://instagram.com/javilinmadrid/`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <p className="text-uppercase mb-0 text-light">
                                        <InstagramIcon
                                            style={{ fontSize: 30 }}
                                        />{' '}
                                        @javilinmadrid
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 d-flex align-content-center justify-content-center">
                        <Button
                            style={{
                                backgroundColor: '#1DEA87',
                                borderColor: '#1DEA87',
                                color: '#000',
                            }}
                            className="my-2 mx-md-5 shadow pointer"
                            onClick={() => setOpenVideo(!openVideo)}
                        >
                            {openVideo ? 'Cerrar' : 'Ver'} video de presentación
                        </Button>
                    </div>
                </div>
            </aside>
            {loading && <Spinner />}
            {openVideo && (
                <div className="m-auto" style={{ maxWidth: '600px' }}>
                    <div className="player-wrapper m-2">
                        <ReactPlayer
                            className="react-player"
                            url="https://vimeo.com/548924865"
                            width="100%"
                            height="100%"
                            playing
                            responsive="true"
                            controls
                        />
                    </div>
                </div>
            )}
            {pageInfo &&
                pageInfo.map((elm) => (
                    <Card
                        key={elm.title}
                        title={elm.title}
                        subtitle={elm.subTitle}
                        image={elm.imageUrl[0]}
                        link={elm.slug}
                    />
                ))}
            <aside className="aside-home">
                <h3>
                    Diversión, deporte, salud y motivación <br />
                    Pero sobre todo... Alegría
                </h3>
            </aside>
            {/* <Grid component="section" className="align-items-center" container spacing={0}>
        <Grid item xs={12} md={6}>
          <div className="homeSectionFirst py-3" style={{ backgroundColor: '#1DEA87' }}>
            <img style={{ marginLeft: '16%', width: 200, marginBottom: '1rem' }} src="/img/RF-Logo-negro.png" alt="RondaFit logo negro" />
            <h2 style={{ marginLeft: '16%' }} className="text-uppercase mb-0">Equipo RondaFit</h2>
            <TeacherBar />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="homeSectionRight">
            <h3>¿Qué es RondaFit?</h3>
            <ul>
              <li><strong>Luchar</strong> cuando creías que no podías más</li>
              <li><strong>Reírse</strong> frente al dolor</li>
              <li><strong>Creer</strong> que tu meta está cada vez más cerca</li>
              <li><strong>Sentir</strong> que eres parte de un equipo que te hace mejor</li>
              <li><strong>Retar</strong> a la mejor versión de ti</li>
              <li><strong>Empujar</strong> tus límites mientras te diviertes</li>
              <li><strong>Compartir </strong> sudor y risas</li>
              <li><strong>Amar</strong> la vida y amar tu cuerpo</li>
            </ul>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <div className="homeSectionRight">
            <h3>Una comunidad donde profesionales de todo tipo dan clases de fitness,
            yoga, pilates... acompañado de nutrición deportiva, meditación, estiramientos, fisioterapia y mucho más...</h3>
            <h3>Bienvenido a tu equipo</h3>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="homeSectionLeft" style={{ backgroundImage: 'url("/img/ronda-home.jpg")' }}>
            <SucriptionCard />
          </div>
        </Grid>
      </Grid> */}

            <img
                className="hero-img d-none d-sm-block"
                src="/img/home1.jpg"
                alt="RondaFit Slogan"
            />
            <img
                className="hero-img d-block d-sm-none"
                src="/img/mobile-home1.jpg"
                alt="RondaFit Slogan"
            />
        </>
    );
}
