export default function validateLogin(values){

    let errors = {}

    //Validar email
    if(!values.email){
        errors.email = "El Email es obligatorio";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email.trim())){
        errors.email=" Email no válido"
    }

    if(!values.password){
        errors.password = "La contraseña es obligatoria"
    }else if( values.password.length < 6) {
        errors.password = "La contraseña debe tener al menos 6 caracteres"
    }

    return errors

}