import React, { useState, useContext, useEffect } from 'react';
import { FirebaseContext } from '../../context/Firebase.context';

/* ----ROUTES----*/
import { useHistory, Link } from 'react-router-dom';

/* ----UI----*/
import Boton from '../ui/Boton';

/* ----STYLE COMPONENTS----*/
import Nav from 'react-bootstrap/Nav';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
/* ----ICONS---- */
import MenuIcon from '@material-ui/icons/Menu';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import CloseIcon from '@material-ui/icons/Close';

const Header = () => {
    const history = useHistory();
    const { user, firebase, info, setInfo } = useContext(FirebaseContext);
    const [showDrawer, setShowDrawer] = useState(false);

    const closeSession = () => {
        firebase.logout();
        setInfo({});
        history.push('/');
    };

    const [pageInfo, setPageInfo] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const pageData = await firebase.db.collection('pages');
            pageData.onSnapshot(handleSnapshot);
        };
        fetchData();
        return () => {
            setPageInfo(null);
        };
        // eslint-disable-next-line
    }, []);

    function handleSnapshot(snapshot) {
        let pages = snapshot.docs.map((doc) => {
            return {
                id: doc.id,
                ...doc.data(),
            };
        });
        pages = pages.sort((a, b) => a.order - b.order);
        setPageInfo(pages);
        setLoading(false);
    }

    return (
        <header className="main-header">
            <AppBar
                position="fixed"
                className="navbar-main"
                style={{
                    color: 'black',
                    backgroundColor: 'rgba(29, 234, 135, 0.90)',
                }}
            >
                <Toolbar className="d-flex justify-content-between">
                    <div className="">
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            edge="start"
                            onClick={() => setShowDrawer(!showDrawer)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>

                    <Typography
                        className="fitcontent-width"
                        variant="h6"
                        noWrap
                    >
                        <Link to="/">
                            <img
                                className="logo"
                                src="/img/RF-Logo-negro.png"
                                alt="RondaFit logo"
                            />
                        </Link>
                    </Typography>

                    {/* <Hidden smDown implementation="css">
                        <Nav className="ml-auto">
                            <Link><Link href="#">Colección</Link></Link>
                            {!user ? <Link><Link href="/crear-cuenta">Crear cuenta</Link></Link> : null}
                            {user ? <Boton bgColor="true" onClick={() => firebase.logout()}>Cerrar sesión</Boton> : null}
                        </Nav>
                    </Hidden> */}

                    <div className="fitcontent-width">
                        {user ? (
                            <>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip style={{ zIndex: '2000' }}>
                                            Editar Cuenta
                                        </Tooltip>
                                    }
                                >
                                    <IconButton>
                                        <Link
                                            style={{ fontSize: '1rem' }}
                                            to="/miperfil"
                                        >
                                            <PersonOutlineOutlinedIcon
                                                style={{ color: 'black' }}
                                            />
                                        </Link>
                                    </IconButton>
                                </OverlayTrigger>{' '}
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip style={{ zIndex: '2000' }}>
                                            Cerrar Sesión
                                        </Tooltip>
                                    }
                                >
                                    <IconButton onClick={closeSession}>
                                        <ExitToAppOutlinedIcon
                                            style={{ color: 'black' }}
                                        />
                                    </IconButton>
                                </OverlayTrigger>{' '}
                            </>
                        ) : (
                            <>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip style={{ zIndex: '2000' }}>
                                            Iniciar sesión
                                        </Tooltip>
                                    }
                                >
                                    <IconButton style={{ fontSize: '1rem' }}>
                                        <Link to="/iniciar-sesion">
                                            <PersonOutlineOutlinedIcon
                                                style={{ color: 'black' }}
                                            />
                                        </Link>
                                    </IconButton>
                                </OverlayTrigger>{' '}
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip style={{ zIndex: '2000' }}>
                                            Registrarse
                                        </Tooltip>
                                    }
                                >
                                    <IconButton style={{ fontSize: '1rem' }}>
                                        <Link to="/crear-cuenta">
                                            <PersonAddOutlinedIcon
                                                style={{ color: 'black' }}
                                            />
                                        </Link>
                                    </IconButton>
                                </OverlayTrigger>{' '}
                            </>
                        )}
                    </div>
                </Toolbar>
            </AppBar>

            <nav>
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={showDrawer}
                    onClose={() => setShowDrawer(!showDrawer)}
                    ModalProps={{ keepMounted: true }}
                >
                    <IconButton onClick={() => setShowDrawer(!showDrawer)}>
                        <CloseIcon />
                    </IconButton>
                    <Nav className="mx-auto responsive-navbar">
                        <Link to="/" onClick={() => setShowDrawer(!showDrawer)}>
                            <img
                                src="/img/RF-Logo-negro.png"
                                alt="RondaFit logo"
                            />
                        </Link>
                        {pageInfo &&
                            pageInfo.map((elm) => (
                                <Link
                                    to={`/pagina/${elm.slug}`}
                                    key={elm.title}
                                    className="py-2"
                                    onClick={() => setShowDrawer(!showDrawer)}
                                >
                                    {elm.title}
                                </Link>
                            ))}
                        {user && (
                            <Link
                                to="/clases"
                                className="py-2"
                                onClick={() => setShowDrawer(!showDrawer)}
                            >
                                Reto 100 días
                            </Link>
                        )}
                        {info.role === 'admin' && (
                            <Link
                                to="/panel-administracion"
                                className="py-2"
                                onClick={() => setShowDrawer(!showDrawer)}
                            >
                                Panel de administracion
                            </Link>
                        )}
                        {/* {user && <Link to="/admin/nuevo-video" className="py-2" onClick={() => setShowDrawer(!showDrawer)}>Subir video</Link>}
                        {user && <Link to="/admin/nuevo-profesional" className="py-2" onClick={() => setShowDrawer(!showDrawer)}>Nuevo Profesional</Link>} */}

                        {!user ? (
                            <Link
                                to="/crear-cuenta"
                                className="py-2"
                                onClick={() => setShowDrawer(!showDrawer)}
                            >
                                Crear cuenta
                            </Link>
                        ) : null}
                        {user ? (
                            <Boton
                                className="bottom0"
                                bgColor="true"
                                onClick={() => {
                                    closeSession();
                                    setShowDrawer(!showDrawer);
                                }}
                            >
                                Cerrar sesión
                            </Boton>
                        ) : null}
                    </Nav>
                </Drawer>
            </nav>
        </header>
    );
};
export default Header;
