import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FirebaseContext } from '../../context/Firebase.context';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';

import Boton from './Boton';

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 300,
        backgroundColor: '#00000085',
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardListing: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        textAlign: 'center',
        color: 'white',
    },
    cardHeader: {
        backgroundColor: theme.palette.grey[200],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
        color: 'white',
    },
}));

const SuscriptionCard = () => {
    const classes = useStyles();
    const { subcriptionSettings, info } = useContext(FirebaseContext);

    const [price, setPrice] = useState(null);
    const [validUntil, setValidUntil] = useState(null);

    useEffect(() => {
        if (subcriptionSettings) {
            setPrice(subcriptionSettings.price);
            setValidUntil(new Date(subcriptionSettings.validUntil));
        }
    }, [subcriptionSettings]);

    return (
        <div className="d-flex justify-content-center my-5">
            <Card className={classes.card}>
                <CardHeader
                    title="Suscríbete ahora"
                    // subheader={validUntil && `disfrútalo hasta el ${validUntil.getDate()}/${validUntil.getMonth() + 1}/${validUntil.getFullYear()}`}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                />
                <CardContent>
                    <div className={classes.cardPricing}>
                        <Typography component="h2" variant="h3">
                            49.90 €
                        </Typography>
                    </div>
                    <ul className={classes.cardListing}>
                        <li>Más de 100 videos</li>
                        <li>Desde casa, gimnasio o al aire libre</li>
                        <li>Todas los videos incluídos</li>
                        <li>Conéctate desde cualquier dispositivo</li>
                        <li>
                            Contacta por whatsapp y pago por bizum entre otros
                        </li>
                    </ul>
                </CardContent>
                <CardActions style={{ justifyContent: 'center' }}>
                    <Boton bgColor="true">¡Contacta actívate! 693012063</Boton>
                    {/* <Link to="/suscripcion" style={{ width: '100%' }}>
                        {info.active ? <Boton bgColor="true">Regalar suscripción</Boton> : <Boton bgColor="true">Empezar</Boton>}
                    </Link> */}
                </CardActions>
            </Card>
        </div>
    );
};

export default SuscriptionCard;
