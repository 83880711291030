import React from 'react';
import { useHistory } from 'react-router-dom';

export default function Card({ title, subtitle, image, link }) {
    const history = useHistory();

    return (
        <>
            <div
                className="m-4 shadow pointer"
                onClick={() => history.push(`/pagina/${link}`)}
                style={{
                    borderRadius: '10px',

                    backgroundImage: `url('${image}')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div
                    className="p-4 w-100 h-100 d-flex justify-content-center align-items-center text-uppercase"
                    style={{
                        borderRadius: '10px',
                        backgroundColor: '#656565a3',
                    }}
                >
                    <div className="d-flex flex-column align-items-center">
                        <h2
                            className="m-0 text-center"
                            style={{ color: 'white', fontSize: '1.5rem' }}
                        >
                            {title}
                        </h2>
                        {subtitle && (
                            <small
                                className="text-center"
                                style={{ color: 'white', fontSize: '1rem' }}
                            >
                                {subtitle}
                            </small>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
