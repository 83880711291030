import React, { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../../context/Firebase.context';
import SucriptionCard from '../ui/SuscriptionCard';

import TeacherList from '../layout/TeacherList';
import Spinner from '../ui/Spinner';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

export default function Profesionales() {
    const [weeks, setWeeks] = useState([]);
    const [loading, setLoading] = useState(false);

    const { firebase, info } = useContext(FirebaseContext);

    useEffect(() => {
        const getWeeks = () => {
            setLoading(true)
            firebase.db
                .collection('weeks')
                .orderBy('order', 'asc')
                .onSnapshot(handleSnapshot);
                
        };
        getWeeks();
        // eslint-disable-next-line
    }, []);

    function handleSnapshot(snapshot) {
        const weeks = snapshot.docs.map((doc) => {
            return {
                id: doc.id,
                ...doc.data(),
            };
        });
        
        setWeeks(weeks.sort((a, b) => a.order - b.order));
        setLoading(false);
    }

    return (
        <Container className="my-5">
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <h1 className="text-center text-uppercase mb-0">
                        RondaFit 100 días
                    </h1>
                    {info.active === false && (
                        <>
                            <p className="text-center mb-0 font-italic">
                                1 Hora de entrenamiento desde cualquier lugar
                            </p>
                            <p className="text-center mb-0 font-italic">
                                100 días de entrenamiento a tu disposición
                            </p>
                        </>
                    )}
                    <h4 className="text-center mb-4 font-italic">
                        Toda la magia sucede dentro de tí
                    </h4>

                    {info.active === true && (
                        <>
                            <Grid container spacing={3}>
                                {weeks.map((teacher) => (
                                    <TeacherList
                                        key={teacher.id}
                                        teacher={teacher}
                                    />
                                ))}
                            </Grid>
                        </>
                    )} 
                    {info.active === false && (
                        <>
                            <div className="d-flex justify-content-center">
                                <Grid item xs={12} md={6}>
                                    <div
                                        className="homeSectionLeft"
                                        style={{
                                            backgroundImage:
                                                'url("/img/ronda-home.jpg")',
                                        }}
                                    >
                                        <SucriptionCard />
                                    </div>
                                </Grid>
                            </div>
                        </>
                    )} 
                </>
            )}
        </Container>
    );
}
