import React, { useState, useContext } from 'react';
import { FirebaseContext } from '../../context/Firebase.context';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';

import { Formulario } from '../ui/Formulario';
import ChangeEmailForm from '../layout/account/ChangeEmailForm';
import ChangePasswordForm from '../layout/account/ChangePasswordForm';
import DeleteUserForm from '../layout/account/DeleteUserForm';
import ActivateByCode from './admin/activateByCode';

import Error404 from '../layout/noticeUserToLogin';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';

export default function MiPerfil() {
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [renderComponent, setRenderComponent] = useState(null);

    const { user, info } = useContext(FirebaseContext);

    const selectedComponent = (key) => {
        switch (key) {
            case 'email':
                setRenderComponent(
                    <ChangeEmailForm
                        emailprop={user.email}
                        email={user.email}
                        setIsVisibleModal={setIsVisibleModal}
                    />
                );
                setIsVisibleModal(true);
                break;
            case 'password':
                setRenderComponent(
                    <ChangePasswordForm setIsVisibleModal={setIsVisibleModal} />
                );
                setIsVisibleModal(true);
                break;
            case 'delete':
                setRenderComponent(
                    <DeleteUserForm setIsVisibleModal={setIsVisibleModal} />
                );
                setIsVisibleModal(true);
                break;
            default:
                break;
        }
    };

    return (
        <>
            {!user ? (
                <Error404 />
            ) : (
                <Container className="my-5">
                    {info.name ? (
                        <h1 className="text-center">Hola {info.name}</h1>
                    ) : (
                        <h1 className="text-center">Mi Perfil</h1>
                    )}
                    <Formulario>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    disabled
                                    label="Correo electrónico"
                                    name="email"
                                    autoComplete="email"
                                    type="text"
                                    value={user.email}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    disabled
                                    label="Nombre"
                                    name="name"
                                    type="text"
                                    value={info.name ? info.name : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    disabled
                                    label="Teléfono"
                                    name="phone"
                                    type="text"
                                    value={info.phone ? info.phone : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TextField
                                    margin="normal"
                                    variant="outlined"
                                    disabled
                                    id="status"
                                    label="Reto 100 días"
                                    name="status"
                                    type="text"
                                    value={info.active ? 'Activo' : 'No Activo'}
                                    fullWidth
                                />
                            </Grid>
                            {/* <Grid item xs={6} sm={3}>
                <DatePicker
                  selected={info.activeUntil}
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Grid> */}
                        </Grid>

                        {/* {!info.active && <>

              <h4 className="mt-4">¿Tienes un código para activar tu suscripción?</h4>
              <ActivateByCode />
              <h4>¿Quieres formar parte de RondaFit y tener acceso a todo el contenido?</h4>
              <Grid className="my-4" item xs={12} sm={6}>
                <Link to="/suscripcion"><Button block variant="outline-dark py-3" style={{ backgroundColor: "#1DEA87" }}>Suscríbete</Button></Link>
              </Grid>
            </>} */}
                        {/* {info.active && <>
              <h4 className="mt-4">¿Quieres regalar una suscripción de RondaFit a un amigo o familiar?</h4>
              <Grid className="my-4" item xs={12} sm={6}>
                <Link to="/suscripcion"><Button block variant="outline-dark py-3" style={{ backgroundColor: "#1DEA87" }}>Regalar suscripción</Button></Link>
              </Grid>
            </>} */}
                        <hr className="my-4"></hr>
                        <Grid container spacing={2} className="my-3">
                            <Grid item xs={12} sm={4}>
                                <Button
                                    block
                                    variant="outline-dark"
                                    className="py-3 btn-cool"
                                    onClick={() => selectedComponent('email')}
                                >
                                    Modificar información
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    block
                                    variant="outline-dark"
                                    className="py-3"
                                    onClick={() =>
                                        selectedComponent('password')
                                    }
                                >
                                    Modificar contraseña
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    block
                                    variant="danger"
                                    className="py-3"
                                    onClick={() => selectedComponent('delete')}
                                >
                                    Eliminar cuenta
                                </Button>
                            </Grid>

                            {/* <Grid item sm={6} >
                <TextField
                  // variant="outlined"
                  margin="normal"

                  fullWidth
                  id="name"
                  label="Nombre"
                  name="name"
                  autoComplete="name"
                  type="text"
                  value={info.name && info.name}
                />

              </Grid>
              <Grid item sm={6}>
                <TextField
                  // variant="outlined"
                  margin="normal"

                  fullWidth
                  id="age"
                  label="Edad"
                  name="age"
                  autoComplete="age"
                  type="number"
                  value={info.age && info.age}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  // variant="outlined"
                  margin="normal"

                  fullWidth
                  id="city"
                  label="Ciudad"
                  name="city"
                  autoComplete="city"
                  type="text"
                  value={info.city && info.city}
                />
              </Grid> */}
                        </Grid>

                        <Modal
                            show={isVisibleModal}
                            onHide={() => setIsVisibleModal(false)}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            {renderComponent}
                        </Modal>
                    </Formulario>
                </Container>
            )}
        </>
    );
}
