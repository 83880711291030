import { useState, useEffect } from 'react';
import firebase from '../firebase/firebase';

function useAuthentication() {
    const [authenticateUser, setAuthenticateUser] = useState(null);

    useEffect(() => {
        const unsuscribe = firebase.auth.onAuthStateChanged((user) => {
            if (user) {
                setAuthenticateUser(user);
            } else {
                setAuthenticateUser(null);
            }
        });
        return () => unsuscribe();
    }, []);

    return authenticateUser;
}

export default useAuthentication;
