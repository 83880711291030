import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import FileUploader from 'react-firebase-file-uploader';
import { Formulario, InputSubmit, Error } from '../../ui/Formulario';
import { FirebaseContext } from '../../../context/Firebase.context';
import Swal from 'sweetalert2';

// import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select2 from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

//Validaciones
import useValidation from '../../../hooks/useValidation';
import validateNewVideo from '../../../validation/validateNewVideo';

const EditVideo = ({ id, name1, description1, imageUrl1, teacher }) => {
    const { firebase, info } = useContext(FirebaseContext);
    const history = useHistory();

    // eslint-disable-next-line no-unused-vars
    const [imageName, setImageName] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [uploading, setUploading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [progress, setProgress] = useState(0);
    const [imageUrl, setImageUrl] = useState(imageUrl1);

    // const [error, setError] = useState(false)

    const STATE_INICIAL = {
        name: name1,
        description: description1,
        teacherID: teacher,
    };

    const { values, errors, handleSubmit, handleChange } = useValidation(
        STATE_INICIAL,
        validateNewVideo,
        editVideo
    );

    const { name, description, teacherID } = values;

    async function editVideo() {
        firebase.db.collection('videos').doc(id).update({
            name: name,
            description: description,
            imageUrl: imageUrl,
            teacherID: teacherID,
        });
        Swal.fire('El video ha sido editado', '', 'success');

        // history.push(`/clases`);
    }

    const handleUploadStart = () => {
        setUploading(true);
        setProgress(0);
    };

    const handleProgress = (progress) => setProgress({ progress });

    const handleUploadError = (error) => {
        setUploading(error);
    };

    const handleUploadSuccess = (name) => {
        setProgress(100);
        setUploading(false);
        setImageName(name);
        firebase.storage
            .ref('videos')
            .child(name)
            .getDownloadURL()
            .then((url) => {
                setImageUrl(url);
            });
    };

    const [teachers, setTeachers] = useState([]);
    // const [teacherID, setTeacherId] = useState('');

    useEffect(() => {
        const getWeeks = () => {
            firebase.db
                .collection('weeks')
                .orderBy('createdAt', 'desc')
                .onSnapshot(handleSnapshot);
        };
        if (info.role) getWeeks();
        // eslint-disable-next-line
    }, []);

    function handleSnapshot(snapshot) {
        const teachers = snapshot.docs.map((doc) => {
            return {
                id: doc.id,
                slug: doc.slug,
                ...doc.data(),
            };
        });
        setTeachers(teachers);
    }

    // const handleChangeSelect = (e) => {
    //     setTeacherId(e.target.value);
    // };

    return (
        <>
            {info.role === 'admin' && (
                <Formulario onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Nombre del video"
                                name="name"
                                type="text"
                                value={name}
                                onChange={handleChange}
                            />
                            <p>
                                *Será el nombre del video a mostrar a los
                                usuarios
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                style={{ minWidth: '100%' }}
                                variant="outlined"
                            >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Elige un grupo
                                </InputLabel>
                                <Select2
                                    labelId="demo-simple-select-outlined-label"
                                    id="teacherID"
                                    displayEmpty
                                    value={teacherID}
                                    name="teacherID"
                                    onChange={handleChange}
                                    label="Elige un grupo"
                                >
                                    {teachers.map((elm) => (
                                        <MenuItem key={elm.id} value={elm.slug}>
                                            {elm.name}
                                        </MenuItem>
                                    ))}
                                </Select2>
                            </FormControl>
                            <p>
                                *Es obligatorio, se asocia al grupo donde
                                aparecerá el video
                            </p>
                        </Grid>
                    </Grid>
                    <TextField
                        id="description"
                        name="description"
                        value={description}
                        label="Descripción del video"
                        required
                        multiline
                        rows={4}
                        fullWidth
                        onChange={handleChange}
                        variant="outlined"
                    />

                    <Grid container className="my-2">
                        <Grid item xs={12} sm={6}>
                            <label className="select-img-box">
                                Seleccionar imagen
                                <FileUploader
                                    hidden
                                    accept="image/*"
                                    id="image"
                                    name="image"
                                    randomizeFilename
                                    storageRef={firebase.storage.ref('videos')}
                                    onUploadStart={handleUploadStart}
                                    onUploadError={handleUploadError}
                                    onUploadSuccess={handleUploadSuccess}
                                    onProgress={handleProgress}
                                />
                            </label>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="d-flex justify-content-center align-items-center mt-3 px-4"
                        >
                            {imageUrl && (
                                <img
                                    src={imageUrl}
                                    alt="imagen temporal"
                                    className="form-image"
                                />
                            )}
                        </Grid>
                    </Grid>

                    <InputSubmit
                        type="submit"
                        value="Guardar cambios"
                        style={{ marginTop: '1rem' }}
                    />

                    {/* {error && <Error>{error}</Error>} */}
                    {errors.name && <Error>{errors.name}</Error>}
                    {errors.description && <Error>{errors.description}</Error>}
                    {errors.image && <Error>{errors.image}</Error>}
                </Formulario>
            )}
        </>
    );
};

export default EditVideo;
