import React, { useState, useEffect } from 'react';

export default function Pagination({
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    maxPaginationNumbers,
    projectTable,
    contentLength,
    optionsProp,
}) {
    const pages = Math.ceil(contentLength / perPage);

    const getInitial = (length) => {
        if (currentPage - Math.floor(length / 2) <= 0 || pages < length)
            return 1;
        else if (currentPage + Math.floor(length / 2) >= pages)
            return pages - length + 1;
        else return currentPage - Math.floor(length / 2);
    };

    useEffect(() => {
        const maxPag = maxPaginationNumbers ? maxPaginationNumbers : 5;
        const length = pages < maxPag ? pages : maxPag;

        const initial = getInitial(length);
        const res = [];
        for (let i = initial; i < initial + length; i++) {
            res.push(i);
        }
        setPaginationNumbers(res);
        return () => {
            setPaginationNumbers([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, perPage]);

    const prevNextPage = (side) => {
        if (side === 'next' && currentPage < pages) {
            setCurrentPage(currentPage + 1);
        } else if (side === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const options = optionsProp || [10, 20, 30];

    const [paginationNumbers, setPaginationNumbers] = useState([1, 2, 3, 4, 5]);
    return (
        <div
            className={` ${
                projectTable ? '' : 'bg-white'
            } position-relative top-0 left-0 pb-3 pt-6 flex align-items-center justify-content-between px-sm-6 rounded position-lg-static w-auto`}
        >
            <div className="d-flex justify-content-between d-sm-none">
                <button
                    onClick={() => prevNextPage('prev')}
                    className="position-relative d-inline-flex align-items-center px-2 py-2 rounded border bg-white"
                >
                    <span>Anterior</span>
                </button>
                <button
                    onClick={() => prevNextPage('next')}
                    className={` ${
                        projectTable ? '' : 'bg-white'
                    } position-relative d-inline-flex align-items-center px-2 py-2 rounded border`}
                >
                    <span>Siguiente</span>
                </button>
            </div>
            <div className="d-none  d-sm-flex align-items-sm-center justify-content-sm-between">
                <div className="d-flex align-items-center">
                    <p className="m-0">Filas por página</p>
                    <select
                        name="select"
                        className="ml-2"
                        value={perPage}
                        onChange={(e) => setPerPage(e.target.value)}
                    >
                        {options.map((el) => (
                            <option key={el} value={el}>
                                {el}
                            </option>
                        ))}
                    </select>
                    <p className="m-0 pl-2"> de {contentLength} </p>
                </div>
                <div>
                    <nav
                        className="position-relative d-inline-flex rounded"
                        aria-label="Pagination"
                    >
                        <button
                            onClick={() => prevNextPage('prev')}
                            className={` ${
                                projectTable ? '' : 'bg-white'
                            } mr-4 position-relative d-inline-flex align-items-center p-2 rounded-xl border-gray-300 text-sm font-medium hover:bg-blue-select`}
                        >
                            <span>Anterior</span>
                        </button>
                        {paginationNumbers.map((pag) => (
                            <button
                                key={pag + 76}
                                onClick={() => setCurrentPage(pag)}
                                className={`
                                        ${
                                            currentPage === pag &&
                                            'bg-success text-white'
                                        } mx-1 p-2 rounded`}
                            >
                                {pag}
                            </button>
                        ))}

                        <button
                            onClick={() => prevNextPage('next')}
                            className={` ${
                                projectTable ? '' : 'bg-white'
                            } ml-4 position-relative d-inline-flex align-items-center p-2 rounded`}
                        >
                            <span>Siguiente</span>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );
}
