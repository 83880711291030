import React from 'react';

import Container from '@material-ui/core/Container'

const LegalAdvise = () => {
    return (

        <Container className="my-5">

            <h1 className="text-center">Aviso Legal</h1>

            <p>Francisco Javier Garcia Diaz, provisto con NIF/CIF 74934085Q, dirección
            Plaza CARLOS CAMBRONERO 1, 28004 Madrid, no asume ninguna responsabilidad derivada del uso
incorrecto, inapropiado o ilícito de la información aparecida en la página de Internet de www.rondafit.com</p>
<p>
Con los límites establecidos en la ley, Francisco Javier Garcia Diaz no asume ninguna responsabilidad
derivada de la falta de veracidad, integridad, actualización y precisión de los datos o informaciones que se contienen en
sus páginas de Internet.
</p>
<p>
Los contenidos e información no vinculan a Francisco Javier Garcia Diaz ni constituyen opiniones, consejos o
asesoramiento legal de ningún tipo pues se trata meramente de un servicio ofrecido con carácter informativo y
divulgativo.
</p>
<p>
La página de Internet de www.rondafit.com puede contener enlaces (links) a otras páginas de terceras
partes que Francisco Javier Garcia Diaz no puede controlar. Por lo tanto,
Francisco Javier Garcia Diaz asume responsabilidades por el contenido que pueda aparecer en
páginas de terceros.
</p>
<p>
Los textos, imágenes, sonidos, animaciones, software y el resto de contenidos incluidos en este website son propiedad
exclusiva de Francisco Javier Garcia Diaz o sus licenciantes. Cualquier acto de transmisión, distribución,
cesión, reproducción, almacenamiento o comunicación pública total o parcial, debe contar con el consentimiento
expreso de Francisco Javier Garcia Diaz.
</p>
<p>
Asimismo, para acceder a algunos de los servicios que Francisco Javier Garcia Diaz ofrece a través del website
deberá proporcionar algunos datos de carácter personal. Igualmente le informamos que, respecto de los datos suministrados por usted, Francisco Javier García Díaz gestiona los mismos en cumplimiento de la Ley Orgánica 3/2018, de 5 de diciembre,
 de Protección de Datos Personales y garantía de los derechos digitales y del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016. En consecuencia, en caso de considerarlo oportuno, usted podrá ejercer, respecto de sus datos personales,
 los derechos de acceso, rectificación, supresión, limitación del tratamiento, portabilidad y oposición que le asisten en calidad de titular de los mismos, pudiendo para ello dirigirse a la siguiente dirección: Plaza CARLOS CAMBRONERO 1, 28004 Madrid.
</p>

        </Container>

    );
}

export default LegalAdvise;