import React, { useState, useContext } from 'react'
import { FirebaseContext } from "../../../context/Firebase.context"
import Modal from 'react-bootstrap/Modal'
import TextField from '@material-ui/core/TextField';

import { InputSubmit, Error } from "../../../components/ui/Formulario"
import { useHistory } from 'react-router-dom'
// import Router from "next/router"

import Swal from 'sweetalert2'


export default function DeleteUserForm({ setIsVisibleModal }) {
    let { firebase } = useContext(FirebaseContext)
    const history = useHistory()
    const [error, setError] = useState('')
    const handleChange = e => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }
    const [values, setValues] = useState({ password: '' })

    const { password } = values

    const handleSubmit = e => {
        e.preventDefault();
        deleteUser()
    }

    function deleteUser() {
        firebase.reauthenticate(password)
            .then(async function () {
                try {
                    await firebase.deleteUser()
                    Swal.fire(
                        'Tu cuenta ha sido borrada',
                        '',
                        'success'
                    )
                    setIsVisibleModal(false)
                    history.push("/")
                } catch (error) {
                    setError("Error al borrar la cuenta, inténtelo de nuevo más tarde")
                }
            })
            .catch(function (error) {
                setError("Contraseña incorrecta, pruebe otra vez")
            });
    }
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    Borrar cuenta
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="modal-box">
                        <p>Debes introducir tu contraseña para confirmar el borrado de tu cuenta, ten en cuenta que no podrás recuperarla y que cualquier información o suscripción se perderá.</p>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Confirma tu contraseña"
                            type="password"
                            id="password"
                            value={password}
                            onChange={handleChange}
                            inputProps={{ title: "Tu contraseña debe incluir más de 6 caracteres", pattern: ".{6,}" }}
                            autoComplete="current-password" />

                        {error && <Error>{error}</Error>}
                        <InputSubmit
                            type="submit"
                            value="Eliminar cuenta"
                        />
                    </div>
                </form>
            </Modal.Body>
        </>
    )
}
