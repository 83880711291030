import app from 'firebase/app';
import firebaseConfig from './config';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';

class Firebase {
    constructor() {
        if (!app.apps.length) {
            app.initializeApp(firebaseConfig);
        }
        this.auth = app.auth();
        this.db = app.firestore();
        this.storage = app.storage();
        this.database = app.database();
        this.authReauthenticate = app.auth;
    }

    async register(email, password, nameProp, phone) {
        const newUser = await this.auth.createUserWithEmailAndPassword(
            email,
            password
        );
        const userInfo = this.db.collection('users').doc(newUser.user.uid);
        return await userInfo.set({
            email: email.toLowerCase(),
            name: nameProp,
            phone: phone,
            lastname: '',
            age: '',
            city: '',
            info: '',
            createdAt: Date.now(),
            active: false,
            activationDate: null,
            activeUntil: '',
            role: 'user',
        });
        // return await newUser.user.updateProfile({
        //     displayName: name,
        // })
        // return await this.auth.createUserWithEmailAndPassword(email, password);
    }

    async login(email, password) {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    async logout() {
        return await this.auth.signOut();
    }

    async updateEmail(newEmail) {
        return this.auth.currentUser.updateEmail(newEmail);
    }

    async reauthenticate(password) {
        const user = this.auth.currentUser;
        const credentials = this.authReauthenticate.EmailAuthProvider.credential(
            user.email,
            password
        );
        return user.reauthenticateWithCredential(credentials);
    }
    async currentUser() {
        const user = this.auth.currentUser;
        return user;
    }

    // async paymentconfirm(payerName){
    //     const registerPay = {
    //         uid: this.auth.currentUser.user.uid,
    //         name : payerName,
    //         createdAt: Date.now()
    //     }

    //     await firebase.db.collection('payments').add(registerPay)
    //     return Router.push('/miperfil')
    // }

    async updatePassword(newPassword) {
        return this.auth.currentUser.updatePassword(newPassword);
    }

    async deleteUser() {
        return this.auth.currentUser.delete();
    }

    async resetPassword(email) {
        return this.auth.sendPasswordResetEmail(email);
    }
}

const firebase = new Firebase();

export default firebase;
