import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { InputSubmit, Error } from "../../ui/Formulario"
import firebase from "../../../firebase/firebase"
// Material UI
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'react-bootstrap/Modal'

import ResetPassword from './recuperar-password'

//Validaciones

import useValidation from "../../../hooks/useValidation"
import validateLogin from "../../../validation/validateLogin"

const STATE_INICIAL = {
  email: "",
  password: ""
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: '1rem',
    backgroundColor: 'var(--verde)',
    color: '#111111'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: '3rem',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: 'var(--verde)',
    color: '#111111'
  },
}));

export default function Login() {

  const history = useHistory()

  const [error, setError] = useState(false)

  const { values, errors, handleSubmit, handleChange } = useValidation(STATE_INICIAL, validateLogin, UserLogin);
  const { email, password } = values

  async function UserLogin() {
    try {
      await firebase.login(email.trim().toLowerCase(), password)
      history.push("/")

    } catch (error) {
      setError("Error al iniciar sesión: la contraseña y/o correo electrónico son incorrectos.")
    }
  }

  const [isVisibleModal, setIsVisibleModal] = useState(false)

  const classes = useStyles();

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
        </Avatar>
        <Typography component="h1" variant="h3">
          Iniciar Sesión
            </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            autoFocus
            type="text"
            value={email}
            onChange={handleChange}
            error={errors.email && true}
            helperText={errors.email}
          // inputProps={{ title: "Tu correo debe incluir @ y un dominio. Ej: .com, .es...", pattern: "^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$" }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            value={password}
            onChange={handleChange}
            // inputProps={{ title: "Tu contraseña debe incluir más de 6 caracteres", pattern: ".{6,}" }}
            error={errors.password && true}
            helperText={errors.password}
            autoComplete="current-password" />
          {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="No cerrar sesión" /> */}

          <InputSubmit
            type="submit"
            value="Iniciar sesión"
          />
          {error && <Error className="my-4 px-2">{error}</Error>}
          <p className="mt-2 teacher-list-box text-center" onClick={() => setIsVisibleModal(true)}>¿No recuerdas la contraseña? Recuperar contraseña</p>
          <hr />
          <h4 className="mt-2 text-center">
            <Link to="/crear-cuenta">¿No tienes cuenta? Regístrate</Link>
          </h4>



        </form>
      </div>
      <Modal
        show={isVisibleModal}
        onHide={() => setIsVisibleModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ResetPassword setIsVisibleModal={setIsVisibleModal} />
      </Modal>


    </Container>
  );
}
