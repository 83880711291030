import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import FileUploader from 'react-firebase-file-uploader';
import { InputSubmit, Error } from '../../ui/Formulario';
import { FirebaseContext } from '../../../context/Firebase.context';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

//Validaciones
import useValidation from '../../../hooks/useValidation';
import validateNewPage from '../../../validation/validateNewPage';

export default function NuevaPagina({ pageInfo }) {
    let STATE_INICIAL = pageInfo
        ? {
              title: pageInfo.title,
              subTitle: pageInfo.subTitle,
              description: pageInfo.description,
              description2: pageInfo.description2,
              instagram: pageInfo.instagram,
              phone: pageInfo.phone,
              order: pageInfo.order,
              price: pageInfo.price,
              createdAt: pageInfo.createdAt,
          }
        : {
              title: '',
              subTitle: '',
              description: '',
              description2: '',
              instagram: '',
              phone: '',
              price: '',
              order: 99,
          };

    // useEffect(() => {
    //     console.log(pageInfo);
    // }, [pageInfo]);

    const { firebase, info } = useContext(FirebaseContext);
    const history = useHistory();

    // eslint-disable-next-line no-unused-vars
    const [imageName, setImageName] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [uploading, setUploading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [progress, setProgress] = useState(0);
    const [imageUrl, setImageUrl] = useState(
        pageInfo && pageInfo.imageUrl ? pageInfo.imageUrl : []
    );
    // const [image, setImage] = useState('');

    // const [error, setError] = useState(false)

    let { values, errors, handleSubmit, handleChange } = useValidation(
        STATE_INICIAL,
        validateNewPage,
        createNewPage
    );

    let {
        title,
        subTitle,
        description,
        description2,
        instagram,
        phone,
        price,
        order,
    } = values;

    async function createNewPage() {
        const slug = title.replace(/ /g, '-').toLowerCase().trim();
        const newPage = {
            title,
            subTitle,
            slug,
            description,
            description2,
            instagram,
            phone,
            horario: horario ? horario : [],
            imageUrl,
            price,
            order: order || 99,
            createdAt: Date.now(),
        };
        if (pageInfo) {
            firebase.db.collection('pages').doc(pageInfo.id).update(newPage);
            Swal.fire('La página ha sido actualizada', '', 'success');
        } else {
            firebase.db.collection('pages').add(newPage);
            Swal.fire('La página ha sido creada', '', 'success');
        }

        history.goBack();
    }

    const handleUploadStart = () => {
        setUploading(true);
        setProgress(0);
    };

    const handleProgress = (progress) => setProgress({ progress });

    const handleUploadError = (error) => {
        setUploading(error);
    };

    const handleUploadSuccess = (name) => {
        setProgress(100);
        setUploading(false);
        setImageName(name);
        firebase.storage
            .ref('weeks')
            .child(name)
            .getDownloadURL()
            .then((url) => {
                setImageUrl([...imageUrl, url]);
                // setImageUrl(url);
            });
    };

    const [horario, setHorario] = useState(pageInfo ? pageInfo.horario : []);
    const [handleHorario, setHandleHorario] = useState('');

    const deleteHorario = (idx) => {
        const newHorario = [...horario];
        newHorario.splice(idx, 1);
        setHorario(newHorario);
    };
    const deletePhoto = (idx) => {
        const newPhoto = [...imageUrl];
        newPhoto.splice(idx, 1);
        setImageUrl(newPhoto);
    };

    const handle = () => {
        setHorario([...horario, handleHorario]);
        setHandleHorario('');
    };

    const changePosition = (img, idx) => {
        const imgPrev = [...imageUrl];
        imgPrev.splice(idx, 1);
        imgPrev.unshift(img);
        setImageUrl(imgPrev);
    };

    return (
        <Container>
            {info.role === 'admin' && (
                <>
                    {/* <Formulario onSubmit={handleSubmit} noValidate> */}
                    <div className="my-4">
                        {pageInfo ? (
                            <h1 className="text-center">Modificar página</h1>
                        ) : (
                            <h1 className="text-center">Crear nueva página</h1>
                        )}

                        <TextField
                            className="mb-3"
                            variant="outlined"
                            required
                            fullWidth
                            id="title"
                            label="Título"
                            name="title"
                            type="text"
                            value={title}
                            onChange={handleChange}
                        />
                        <TextField
                            className="mb-3"
                            variant="outlined"
                            required
                            fullWidth
                            id="subTitle"
                            label="Sub Título"
                            name="subTitle"
                            type="text"
                            value={subTitle}
                            onChange={handleChange}
                        />

                        <TextField
                            className="mb-3"
                            id="description"
                            name="description"
                            value={description}
                            label="Descripción"
                            required
                            multiline
                            rows={4}
                            fullWidth
                            onChange={handleChange}
                            variant="outlined"
                        />

                        <TextField
                            className="mb-3"
                            id="description2"
                            name="description2"
                            value={description2}
                            label="Descripción extra"
                            multiline
                            rows={4}
                            fullWidth
                            onChange={handleChange}
                            variant="outlined"
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    id="price"
                                    name="price"
                                    value={price}
                                    label="Precio"
                                    fullWidth
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="order"
                                    name="order"
                                    value={order}
                                    label="Orden"
                                    type="number"
                                    fullWidth
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="phone"
                                    name="phone"
                                    value={phone}
                                    label="Teléfono"
                                    fullWidth
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="instagram"
                                    name="instagram"
                                    value={instagram}
                                    label="Instagram"
                                    fullWidth
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <div className="d-flex mb-3">
                                    <TextField
                                        className="mr-2"
                                        id="horario"
                                        name="horario"
                                        value={handleHorario}
                                        label="Horario"
                                        fullWidth
                                        onChange={(e) =>
                                            setHandleHorario(e.target.value)
                                        }
                                        variant="outlined"
                                    />
                                    <Button onClick={handle} variant="dark">
                                        Añadir
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div>
                                    {horario &&
                                        horario.length > 0 &&
                                        horario.map((elm, idx) => (
                                            <div
                                                className="d-flex"
                                                key={elm + idx}
                                            >
                                                <DeleteForeverIcon
                                                    fontSize="small"
                                                    className="btn-outline-danger pointer"
                                                    onClick={() =>
                                                        deleteHorario(idx)
                                                    }
                                                />
                                                <p className="mb-1">{elm}</p>
                                            </div>
                                        ))}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className="my-2">
                            <Grid item xs={12} sm={6}>
                                <label className="select-img-box">
                                    Seleccionar imagen
                                    <FileUploader
                                        hidden
                                        accept="image/*"
                                        id="image"
                                        name="image"
                                        randomizeFilename
                                        storageRef={firebase.storage.ref(
                                            'weeks'
                                        )}
                                        onUploadStart={handleUploadStart}
                                        onUploadError={handleUploadError}
                                        onUploadSuccess={handleUploadSuccess}
                                        onProgress={handleProgress}
                                    />
                                </label>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                className="d-flex justify-content-center align-items-center mt-3 px-4"
                            >
                                {imageUrl.length > 0 &&
                                    imageUrl.map((elm, idx) => (
                                        <div
                                            key={elm}
                                            className={
                                                idx === 0
                                                    ? 'border pl-3 pr-2 py-1 mr-2 border-success'
                                                    : ''
                                            }
                                        >
                                            <div className="d-flex flex-column mr-2 text-center">
                                                {idx === 0 && (
                                                    <small>Foto ppal</small>
                                                )}
                                                {idx !== 0 && (
                                                    <div>
                                                        <DeleteForeverIcon
                                                            fontSize="small"
                                                            className="btn-outline-danger pointer"
                                                            onClick={() =>
                                                                deletePhoto(idx)
                                                            }
                                                        />
                                                        <small>Borrar</small>
                                                    </div>
                                                )}
                                                <img
                                                    src={elm}
                                                    alt="imagen temporal"
                                                    className="form-image2"
                                                    onClick={() =>
                                                        changePosition(elm, idx)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ))}
                            </Grid>
                        </Grid>

                        <InputSubmit
                            type="button"
                            onClick={handleSubmit}
                            value={pageInfo ? 'Actualizar' : 'Crear'}
                            style={{ marginTop: '1rem' }}
                        />

                        {/* {error && <Error>{error}</Error>} */}
                        {errors.name && <Error>{errors.name}</Error>}
                        {errors.category && <Error>{errors.category}</Error>}
                        {errors.description && (
                            <Error>{errors.description}</Error>
                        )}
                        {errors.image && <Error>{errors.image}</Error>}
                    </div>
                    {/* </Formulario> */}
                </>
            )}
        </Container>
    );
}
