import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import FileUploader from 'react-firebase-file-uploader';
import { Formulario, InputSubmit, Error } from '../../ui/Formulario';
import { FirebaseContext } from '../../../context/Firebase.context';
import Swal from 'sweetalert2';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

//Validaciones
import useValidation from '../../../hooks/useValidation';
import validateNewTeacher from '../../../validation/validateNewTeacher';

const EditTeacher = ({
    id,
    name1,
    category1,
    instagram1,
    description1,
    order1,
    imageUrl1,
}) => {
    const { firebase, info } = useContext(FirebaseContext);
    const history = useHistory();

    // eslint-disable-next-line no-unused-vars
    const [imageName, setImageName] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [uploading, setUploading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [progress, setProgress] = useState(0);
    const [imageUrl, setImageUrl] = useState(imageUrl1);

    // const [error, setError] = useState(false)

    const STATE_INICIAL = {
        name: name1,
        order: order1,
        category: category1,
        instagram: instagram1,
        description: description1,
        referred: 0,
    };

    const { values, errors, handleSubmit, handleChange } = useValidation(
        STATE_INICIAL,
        validateNewTeacher,
        editTeacher
    );

    const { name, order, category, description, instagram } = values;

    // const slug = name.replace(/ /g, "-").toLowerCase().trim()

    async function editTeacher() {
        // this.props.firebase.db.collection("teachers").doc(this.props.teacherRef)
        //     .update({
        //         "inscriptions": this.props.totalRefs.inscriptions + 1,
        //     });
        // const teacherToEdit = {
        //     name,
        //     slug,
        //     category,
        //     imageUrl,
        //     description,
        //     instagram,
        //     inscriptions: 0,
        //     createdAt: Date.now()
        // }

        firebase.db.collection('weeks').doc(id).update({
            name: name,
            order: order,
            category: category,
            description: description,
            instagram: instagram,
            imageUrl: imageUrl,
        });
        Swal.fire('El profesor ha sido editado', '', 'success');

        history.push('/clases');
    }

    const handleUploadStart = () => {
        setUploading(true);
        setProgress(0);
    };

    const handleProgress = (progress) => setProgress({ progress });

    const handleUploadError = (error) => {
        setUploading(error);
    };

    const handleUploadSuccess = (name) => {
        setProgress(100);
        setUploading(false);
        setImageName(name);
        firebase.storage
            .ref('weeks')
            .child(name)
            .getDownloadURL()
            .then((url) => {
                setImageUrl(url);
            });
    };

    return (
        <>
            {info.role === 'admin' && (
                <Formulario onSubmit={handleSubmit} noValidate>
                    {/* <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Nombre del profesor"
                                name="name"
                                type="text"
                                value={name}
                                onChange={handleChange} />
                            <p>*Será el nombre a mostrar a los usuarios</p>
                        </Grid> 
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="order"
                                label="Orden en el listado"
                                name="order"
                                type="number"
                                value={order}
                                onChange={handleChange} />
                            <p></p>
                        </Grid>
                    </Grid> */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={5}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="category"
                                label="Categoría"
                                name="category"
                                type="text"
                                value={category}
                                onChange={handleChange}
                            />
                            
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="instagram"
                                label="Perfil de Instagram"
                                name="instagram"
                                type="text"
                                value={instagram.toLowerCase()}
                                onChange={handleChange}
                            />
                            <p>
                                *Solo el nombre de usuario (username) de
                                instagram
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="order"
                                label="Orden en el listado"
                                name="order"
                                type="number"
                                value={order}
                                onChange={handleChange}
                            />
                            <p></p>
                        </Grid>
                    </Grid>
                    <TextField
                        id="description"
                        name="description"
                        value={description}
                        label="Descripción"
                        required
                        multiline
                        rows={4}
                        fullWidth
                        onChange={handleChange}
                        variant="outlined"
                    />

                    <Grid container className="my-2">
                        <Grid item xs={12} sm={6}>
                            <label className="select-img-box">
                                Seleccionar imagen
                                <FileUploader
                                    hidden
                                    accept="image/*"
                                    id="image"
                                    name="image"
                                    randomizeFilename
                                    storageRef={firebase.storage.ref(
                                        'weeks'
                                    )}
                                    onUploadStart={handleUploadStart}
                                    onUploadError={handleUploadError}
                                    onUploadSuccess={handleUploadSuccess}
                                    onProgress={handleProgress}
                                />
                            </label>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="d-flex justify-content-center align-items-center mt-3 px-4"
                        >
                            {imageUrl && (
                                <img
                                    src={imageUrl}
                                    alt="imagen temporal"
                                    className="form-image"
                                />
                            )}
                        </Grid>
                    </Grid>

                    <InputSubmit
                        type="submit"
                        value="Guardar cambios"
                        style={{ marginTop: '1rem' }}
                    />

                    {/* {error && <Error>{error}</Error>} */}
                    {errors.name && <Error>{errors.name}</Error>}
                    {errors.category && <Error>{errors.category}</Error>}
                    {errors.description && <Error>{errors.description}</Error>}
                    {errors.image && <Error>{errors.image}</Error>}
                </Formulario>
            )}
        </>
    );
};

export default EditTeacher;
