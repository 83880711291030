import React from 'react'
import './spinner.css'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';


const Spinner = () => {
    return (
        <>
            <div className="sk-cube-grid">
                <div className="sk-cube sk-cube1"></div>
                <div className="sk-cube sk-cube2"></div>
                <div className="sk-cube sk-cube3"></div>
                <div className="sk-cube sk-cube4"></div>
                <div className="sk-cube sk-cube5"></div>
                <div className="sk-cube sk-cube6"></div>
                <div className="sk-cube sk-cube7"></div>
                <div className="sk-cube sk-cube8"></div>
                <div className="sk-cube sk-cube9"></div>
            </div>
            <h4 className="text-center mb-3 font-italic">No cuentes los días, haz que los días cuenten <FormatQuoteIcon style={{ fontSize: 50 }} /> </h4>
        </>
    );
}

export default Spinner;