import React, { useEffect, useContext, useState } from 'react';
import { FirebaseContext } from '../../context/Firebase.context';
import queryString from 'query-string';
import Swal from 'sweetalert2';

import { useLocation, useHistory, useParams } from 'react-router-dom';
import Error404 from '../layout/noticeUserToLogin';
import Spinner from '../ui/Spinner';
import Button from 'react-bootstrap/Button';
import NoExisteVideo from '../layout/noExisteVideo';
import VideoAside from '../layout/videoAside';
import ViewPDF from '../ui/ViewPDF';
import EditVideo from '../pages/admin/editar-video';

// import formatDistanceToNow from 'date-fns/formatDistanceToNow';
// import { es } from 'date-fns/locale';
import ReactPlayer from 'react-player';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const Video = (props) => {
    const { firebase, user, info } = useContext(FirebaseContext);
    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    let idVideo = '';
    if (props.location.search) {
        idVideo = queryString.parse(props.location.search);
    } else {
        idVideo = queryString.parse(location.search);
    }
    const vId = idVideo.v;

    const [video, setVideo] = useState({});
    const [videos, setVideos] = useState([]);

    const [error, setError] = useState(false);
    const [editBox, setEditBox] = useState(false);
    const openEditBox = () => {
        setEditBox(!editBox);
    };

    useEffect(() => {
        if (vId) {
            const getVideo = async () => {
                const videoQuery = await firebase.db
                    .collection('videos')
                    .doc(vId);
                const video = await videoQuery.get();

                if (video.exists) {
                    setVideo(video.data());
                } else {
                    setError(true);
                }
            };
            getVideo();

            const getListVideos = () => {
                firebase.db
                    .collection('videos')
                    .orderBy('createdAt', 'desc')
                    .onSnapshot(handleSnapshot);
                //TO DO - PONER ALEATORIAMENTE
            };
            getListVideos();
        }
        // eslint-disable-next-line
    }, [vId, params.video]);

    function handleSnapshot(snapshot) {
        const videos = snapshot.docs.map((doc) => {
            return {
                id: doc.id,
                ...doc.data(),
            };
        });
        setVideos(videos.slice(0, 8));

        // PARA QUE LOS VIDEO DE ASIDE SEAN DEL MISMO TEACHER
        // videosAside = videos.filter(video => video.teacherID = params.profesor )
    }

    const deleteVideo = async () => {
        if (!user) {
            return history.push('/login');
        }

        if (user.role === 'admin') {
            return history.push('/');
        }
        try {
            await firebase.db.collection('videos').doc(vId).delete();
            Swal.fire('El video ha sido borrado', '', 'success');
            history.push('/clases');
        } catch (error) {
            Swal.fire(
                'Hubo un error al borrar el video',
                'Póngase en contacto con el administrador',
                'error'
            );
        }
    };

    // Loading
    if (Object.keys(video).length === 0 && !error)
        return (
            <Container className="my-5">
                <Spinner />
            </Container>
        );

    // const teacherName = params.profesor.replace(/-/g, ' ').toUpperCase();

    return (
        <Container className="my-3" maxWidth="xl">
            {info.active === false ? (
                <Error404 />
            ) : (
                <>
                    {error ? (
                        <NoExisteVideo params={params} />
                    ) : (
                        <>
                            <Grid container spacing={6}>
                                <Grid item xs={12} lg={9}>
                                    <h1>{video.name}</h1>
                                    {info.role === 'admin' && (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    onChange={openEditBox}
                                                    name="openEditBox"
                                                />
                                            }
                                            label="Editar video"
                                        />
                                    )}
                                    {editBox && (
                                        <EditVideo
                                            id={vId}
                                            name1={video.name}
                                            description1={video.description}
                                            imageUrl1={video.imageUrl}
                                            teacher={video.teacherID}
                                        />
                                    )}
                                    {video.videoID && (
                                        <div className="player-wrapper">
                                            <ReactPlayer
                                                className="react-player"
                                                url={video.videoID}
                                                width="100%"
                                                height="100%"
                                                playing
                                                responsive="true"
                                                controls
                                            />
                                        </div>
                                    )}

                                    {video.pdfUrl && (
                                        <ViewPDF
                                            pdfUrl={video.pdfUrl}
                                            videoName={video.name}
                                        />
                                    )}
                                    <hr />
                                    {/* <p className="float-right">
                                        Publicado hace:{' '}
                                        {formatDistanceToNow(
                                            new Date(video.createdAt),
                                            { locale: es }
                                        )}
                                    </p> */}

                                    {/* <p>Impartido por: {teacherName}</p> */}
                                    <p>{video.description}</p>
                                    {info.role === 'admin' && (
                                        <Button
                                            variant="danger"
                                            className="my-2 px-5"
                                            onClick={deleteVideo}
                                        >
                                            Eliminar video
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <h4 className="text-center text-uppercase my-4">
                                        Videos relacionados
                                    </h4>
                                    <Grid container spacing={1}>
                                        {videos.map((elm) => (
                                            <VideoAside
                                                key={elm.id}
                                                video={elm}
                                            />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </>
            )}
        </Container>
    );
};

export default Video;
