import React, { useState, useContext, useEffect } from 'react';
// import Router from "next/router" TO DO METER ROUTER DE REACT para hacer push y recuperar params
import FileUploader from 'react-firebase-file-uploader';
import { Formulario, InputSubmit, Error } from '../../ui/Formulario';
import { FirebaseContext } from '../../../context/Firebase.context';
import ReactPlayer from 'react-player';
import { Redirect, useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select2 from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Swal from 'sweetalert2';

//Validaciones
import useValidation from '../../../hooks/useValidation';
import validateNewVideo from '../../../validation/validateNewVideo';

let STATE_INICIAL = {
    name: '',
    teacherID: '',
    videoID: '',
    description: '',
};

export default function NuevoVideo() {
    const { info, firebase } = useContext(FirebaseContext);
    const history = useHistory();

    // Validaciones y submit
    const { values, errors, handleSubmit, handleChange } = useValidation(
        STATE_INICIAL,
        validateNewVideo,
        createNewVideo
    );
    const { name, videoID, description } = values;

    const [moreErrors, setMoreErrors] = useState({});

    async function createNewVideo() {
        if (!teacherID || !imageUrl) {
            if (!imageUrl)
                setMoreErrors({
                    ...moreErrors,
                    imageUrl: 'La imagen es obligatoria',
                });
            if (!teacherID)
                setMoreErrors({
                    ...moreErrors,
                    teacherID: 'El profesor es obligatorio',
                });
        } else {
            const newVideo = {
                name,
                teacherID,
                videoID,
                imageUrl,
                pdfUrl,
                description,
                votes: 0,
                comentarios: [],
                createdAt: Date.now(),
            };

            firebase.db.collection('videos').add(newVideo);

            Swal.fire('El video ha sido subido', '', 'success');

            setImageUrl('');
            return history.push(`/clases/${teacherID}`);
        }
    }

    // Declaraciones para traer los profesores desde la BBDD
    const [teachers, setTeachers] = useState([]);
    const [teacherID, setTeacherId] = useState('');

    useEffect(() => {
        const getTeachers = () => {
            firebase.db
                .collection('weeks')
                .orderBy('createdAt', 'desc')
                .onSnapshot(handleSnapshot);
        };
        if (info.role) getTeachers();
        // eslint-disable-next-line
    }, []);

    function handleSnapshot(snapshot) {
        const teachers = snapshot.docs.map((doc) => {
            return {
                id: doc.id,
                slug: doc.slug,
                ...doc.data(),
            };
        });
        setTeachers(teachers);
    }

    const handleChangeSelect = (e) => {
        setTeacherId(e.target.value);
    };

    // Declaraciones para la subida de imágenes
    // eslint-disable-next-line no-unused-vars
    const [imageName, setImageName] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [uploading, setUploading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [progress, setProgress] = useState(0);
    const [imageUrl, setImageUrl] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState(false);

    const handleUploadStart = () => {
        setUploading(true);
        setProgress(0);
    };

    const handleProgress = (progress) => setProgress({ progress });

    const handleUploadError = (error) => {
        setUploading(error);
    };

    const handleUploadSuccess = (name) => {
        setProgress(100);
        setUploading(false);
        setImageName(name);
        firebase.storage
            .ref('videos')
            .child(name)
            .getDownloadURL()
            .then((url) => {
                setImageUrl(url);
            });
    };

    // Declaraciones para la subida de pdf
    const [pdfName, setPdfName] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [uploadingPdf, setUploadingPdf] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [progressPdf, setProgressPdf] = useState(0);
    const [pdfUrl, setPdfUrl] = useState('');

    const handleUploadStartPdf = () => {
        setUploadingPdf(true);
        setProgressPdf(0);
    };

    const handleProgressPdf = (progress) => setProgressPdf({ progress });

    const handleUploadErrorPdf = (error) => {
        setUploadingPdf(error);
    };

    const handleUploadSuccessPdf = (name) => {
        setProgressPdf(100);
        setUploadingPdf(false);
        setPdfName(name);
        firebase.storage
            .ref('pdf')
            .child(name)
            .getDownloadURL()
            .then((url) => {
                setPdfUrl(url);
            });
    };

    const [pdfInput, setPdfInput] = useState(false);
    const openPdfInput = () => {
        setPdfInput(!pdfInput);
    };

    return (
        <Container>
            <>
                {info.role !== 'admin' ? (
                    <Redirect to="/" />
                ) : (
                    <>
                        <Formulario onSubmit={handleSubmit} noValidate>
                            <h1 className="text-center">Subir nuevo video</h1>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Título o nombre del video"
                                name="name"
                                type="text"
                                value={name}
                                onChange={handleChange}
                            />
                            <p>
                                *Será el título del video a mostrar a los
                                usuarios
                            </p>

                            <FormControl
                                style={{ minWidth: '100%' }}
                                variant="outlined"
                            >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Elige grupo
                                </InputLabel>
                                <Select2
                                    labelId="demo-simple-select-outlined-label"
                                    id="teacher"
                                    displayEmpty
                                    value={teacherID}
                                    onChange={handleChangeSelect}
                                    label="Elige grupo"
                                >
                                    {teachers.map((elm) => (
                                        <MenuItem key={elm.id} value={elm.slug}>
                                            {elm.name} -{' '}
                                            {elm.category.toUpperCase()}
                                        </MenuItem>
                                    ))}
                                </Select2>
                            </FormControl>
                            <p>
                                *Es obligatorio, se refiere al grupo donde
                                aparecerá el video
                            </p>

                            <TextField
                                id="description"
                                name="description"
                                value={description}
                                label="Descripción del video"
                                required
                                multiline
                                rows={4}
                                fullWidth
                                onChange={handleChange}
                                variant="outlined"
                            />

                            <Grid container className="my-2" spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <label className="select-img-box">
                                        Seleccionar imagen
                                        <FileUploader
                                            hidden
                                            accept="image/*"
                                            id="image"
                                            name="image"
                                            randomizeFilename
                                            storageRef={firebase.storage.ref(
                                                'videos'
                                            )}
                                            onUploadStart={handleUploadStart}
                                            onUploadError={handleUploadError}
                                            onUploadSuccess={
                                                handleUploadSuccess
                                            }
                                            onProgress={handleProgress}
                                        />
                                    </label>
                                    <div className="d-flex justify-content-center align-items-center pt-2">
                                        {imageUrl && (
                                            <img
                                                src={imageUrl}
                                                alt="imagen temporal"
                                                className="form-image"
                                            />
                                        )}
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="videoID"
                                        label="Dirección url del video"
                                        name="videoID"
                                        type="text"
                                        value={videoID}
                                        onChange={handleChange}
                                    />
                                    {/* </Grid>
                <Grid item sm={6}> */}
                                    {videoID && (
                                        <ReactPlayer
                                            url={videoID}
                                            style={{ margin: 'auto' }}
                                            width="200px"
                                            height="120px"
                                            playing
                                            responsive="true"
                                        />
                                    )}
                                </Grid>
                            </Grid>

                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={openPdfInput}
                                        name="openGift"
                                    />
                                }
                                label="¿Quieres añadir un PDF?"
                            />
                            {pdfInput && (
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <label className="select-img-box">
                                            Seleccionar archivo pdf
                                            <FileUploader
                                                hidden
                                                accept=".pdf"
                                                id="pdf"
                                                name="pdf"
                                                // randomizeFilename
                                                storageRef={firebase.storage.ref(
                                                    'pdf'
                                                )}
                                                onUploadStart={
                                                    handleUploadStartPdf
                                                }
                                                onUploadError={
                                                    handleUploadErrorPdf
                                                }
                                                onUploadSuccess={
                                                    handleUploadSuccessPdf
                                                }
                                                onProgress={handleProgressPdf}
                                            />
                                        </label>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        className="d-flex align-items-center"
                                    >
                                        <p className="select-pdf-name text-center">
                                            {pdfName}
                                        </p>
                                    </Grid>
                                </Grid>
                            )}

                            <InputSubmit
                                type="submit"
                                value="Subir nuevo video"
                            />

                            {error && <Error>{error}</Error>}
                            {errors.name && <Error>{errors.name}</Error>}
                            {moreErrors.teacherID && (
                                <Error>{moreErrors.teacherID}</Error>
                            )}
                            {errors.description && (
                                <Error>{errors.description}</Error>
                            )}
                            {moreErrors.imageUrl && (
                                <Error>{moreErrors.imageUrl}</Error>
                            )}
                            {errors.videoID && <Error>{errors.videoID}</Error>}
                        </Formulario>
                    </>
                )}
            </>
        </Container>
    );
}
