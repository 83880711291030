import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from 'react-bootstrap/Button'

const noExisteVideo = ({ params }) => {
    return (
        <div style={{ maxWidth: '600px', margin: '3rem auto' }}>
            <div className="d-flex justify-content-center">
                <img src="/img/RF-Logo-verde.png" alt="RondaFit logo verde" />
            </div>
            <h3 className="text-center mt-5">Oh! parece que el video ya no existe</h3>

            <p className="text-center">Vuelve al listado y encuentra el video que buscas</p>
            <Grid container spacing={4} className="my-5">
                <Grid item xs={6}>
                    <Link to="/clases"><Button block variant="outline-dark">
                        Ver profesionales
                    </Button></Link>
                </Grid>
                <Grid item xs={6}>
                    <Link to={`/clases/${params.profesor}`}><Button block variant="outline-dark">
                        Ver profesor
                    </Button></Link>
                </Grid>
            </Grid>
        </div>
    );
}

export default noExisteVideo;