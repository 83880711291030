export default function validatePassword(values) {

    let errors = {}

    if (!values.password) {
        errors.password = "La contraseña es obligatoria"
    } else if (values.password.length < 6) {
        errors.password = "La contraseña debe tener al menos 6 caracteres"
    }
    if (!values.password) {
        errors.password = "La contraseña es obligatoria"
    } else if (values.password.length < 6) {
        errors.password = "La contraseña debe tener al menos 6 caracteres"
    }

    if (!values.password) {
        errors.password = "La contraseña es obligatoria"
    } else if (values.password.length < 6) {
        errors.password = "La contraseña debe tener al menos 6 caracteres"
    }

    return errors

}