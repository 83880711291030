import React from 'react';
import { Link } from "react-router-dom";

import Grid from '@material-ui/core/Grid';

const TeacherList = ({ teacher }) => {

    const teacherName = teacher.name.toUpperCase()
    const teacherSlug = teacher.name.replace(/ /g, "-").toLowerCase()
    const teacherCategory = teacher.category.toUpperCase()

    return (
        <Grid item xs={6} sm={4} className="d-flex flex-column align-items-center teacher-list-box">
            <Link to={`/clases/${teacherSlug}`} >
                <img className="img-teacher-list shadow-hover" src={teacher.imageUrl} alt={teacher.name} />
            </Link>
            <h3 className="text-center mb-0">{teacherName}</h3>
            <h4 className="text-center text-uppercase">{teacherCategory}</h4>
        </Grid>
    );
}

export default TeacherList;