import React, { useState, useEffect, useContext } from 'react'
import { FirebaseContext } from "../../../../context/Firebase.context"

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

const CuponesRegalo = () => {
    const { firebase } = useContext(FirebaseContext);

    const [cupones, setCupones] = useState([])
    const [cuponesList, setCuponesList] = useState([])

    useEffect(() => {
        const getCupones = () => {
            firebase.db.collection("presents").orderBy("createdAt", "desc").onSnapshot(handleSnapshot)
        }
        getCupones();
        // eslint-disable-next-line
    }, [])

    function handleSnapshot(snapshot) {
        const cuponesFirebase = snapshot.docs.map(doc => {
            return {
                id: doc.id,
                slug: doc.slug,
                ...doc.data()
            }
        });
        setCupones(cuponesFirebase)
        setCuponesList(cuponesFirebase)
    }

    // Buscador cupones
    const [cuponSearched, setCuponSearched] = useState('')
    const handleChangeSearch = e => setCuponSearched(e.target.value)

    useEffect(() => {
        const busqueda = cuponSearched.toLowerCase();
        const filtro = cuponesList.filter(cupon => cupon.emailDest.toLowerCase().includes(busqueda))
        setCupones(filtro);
        // eslint-disable-next-line
    }, [cuponSearched]);

    return (
        <Container className="my-5" maxWidth="sm">
            <h1 className="text-center mb-3">Cupones regalo</h1>
            <input className="form-control my-5"
                value={cuponSearched}
                type="search"
                name="search"
                placeholder="Buscar cupón por correo electrónico" aria-label="Search"
                id="index-input"
                onChange={handleChangeSearch} />

            {cupones && cupones.map(cupon => (
                <Grid key={cupon.id} container spacing={2} >
                    <Grid item xs={7}>
                        <h4>{cupon.emailDest}</h4>
                    </Grid>
                    <Grid item xs={3}>
                        <h4>{cupon.code}</h4>
                    </Grid>
                    <Grid item xs={1}>
                        {cupon.used && 'Usado'}
                    </Grid>
                </Grid>
            ))}

        </Container>

    );
}

export default CuponesRegalo;