import { useState, useEffect } from 'react';

const useValidation = (stateInicial, validate, fn) => {
    const [values, setValues] = useState(stateInicial);
    const [errors, setErrors] = useState({});
    const [submitForm, setSubmitForm] = useState(false);

    useEffect(() => {
        if (submitForm) {
            const noErrors = Object.keys(errors).length === 0;

            if (noErrors) {
                fn(); //Fn = Funcion que se ejecuta en el componente
            }
            setSubmitForm(false);
        }
        // eslint-disable-next-line
    }, [errors]);

    //Funcion que se ejecuta cuando el usuario escribe algo
    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    // Función que se ejecuta cuando el usuario hace submit
    const handleSubmit = (e) => {
        e.preventDefault();
        const ValidationErrors = validate(values);
        setErrors(ValidationErrors);
        setSubmitForm(true);
    };

    //Cuando se realiza el evento blur
    const handleBlur = () => {
        const ValidationErrors = validate(values);
        setErrors(ValidationErrors);
    };

    return {
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
    };
};

export default useValidation;
