import React, { useState, useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import FileUploader from 'react-firebase-file-uploader';
import { Formulario, InputSubmit, Error } from '../../ui/Formulario';
import { FirebaseContext } from '../../../context/Firebase.context';
import Swal from 'sweetalert2';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

//Validaciones
import useValidation from '../../../hooks/useValidation';
import validateNewTeacher from '../../../validation/validateNewTeacher';

const STATE_INICIAL = {
    name: '',
    category: '',
    instagram: '',
    description: '',
    referred: 0,
};

export default function NuevoProfesor() {
    const { firebase, info } = useContext(FirebaseContext);
    const history = useHistory();

    // eslint-disable-next-line no-unused-vars
    const [imageName, setImageName] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [uploading, setUploading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [progress, setProgress] = useState(0);
    const [imageUrl, setImageUrl] = useState('');

    // const [error, setError] = useState(false)

    const { values, errors, handleSubmit, handleChange } = useValidation(
        STATE_INICIAL,
        validateNewTeacher,
        createNewTeacher
    );

    const { name, category, description, instagram } = values;

    const slug = name.replace(/ /g, '-').toLowerCase().trim();

    async function createNewTeacher() {
        const newWeek = {
            name,
            slug,
            category,
            imageUrl,
            description,
            instagram,
            order: 99,
            createdAt: Date.now(),
        };

        firebase.db.collection('weeks').add(newWeek);
        Swal.fire('La categoría ha sido creada', '', 'success');

        history.push('/clases');
    }

    const handleUploadStart = () => {
        setUploading(true);
        setProgress(0);
    };

    const handleProgress = (progress) => setProgress({ progress });

    const handleUploadError = (error) => {
        setUploading(error);
    };

    const handleUploadSuccess = (name) => {
        setProgress(100);
        setUploading(false);
        setImageName(name);
        firebase.storage
            .ref('weeks')
            .child(name)
            .getDownloadURL()
            .then((url) => {
                setImageUrl(url);
            });
    };

    return (
        <Container>
            <>
                {info.role !== 'admin' ? (
                    <Redirect to="/" />
                ) : (
                    <>
                        <Formulario onSubmit={handleSubmit} noValidate>
                            <h1 className="text-center">
                                Crear nueva burbuja en el lista
                            </h1>

                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Título"
                                name="name"
                                type="text"
                                value={name}
                                onChange={handleChange}
                            />
                            <p>*Será el nombre a mostrar a los usuarios</p>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="category"
                                label="Categoría"
                                name="category"
                                type="text"
                                value={category}
                                onChange={handleChange}
                            />
                            <p>
                                *La categoría se refiere al tipo de actividad,
                                por ejemplo: Yoga, Fitness, Nutrición...
                            </p>

                            {/* <TextField
                                variant="outlined"
                                fullWidth
                                id="instagram"
                                label="Perfil de Instagram"
                                name="instagram"
                                type="text"
                                value={instagram.toLowerCase()}
                                onChange={handleChange}
                            />
                            <p>
                                *Solo el nombre de usuario (username) de
                                instagram
                            </p> */}

                            <TextField
                                id="description"
                                name="description"
                                value={description}
                                label="Descripción"
                                required
                                multiline
                                rows={4}
                                fullWidth
                                onChange={handleChange}
                                variant="outlined"
                            />

                            <Grid container className="my-2">
                                <Grid item xs={12} sm={6}>
                                    <label className="select-img-box">
                                        Seleccionar imagen
                                        <FileUploader
                                            hidden
                                            accept="image/*"
                                            id="image"
                                            name="image"
                                            randomizeFilename
                                            storageRef={firebase.storage.ref(
                                                'weeks'
                                            )}
                                            onUploadStart={handleUploadStart}
                                            onUploadError={handleUploadError}
                                            onUploadSuccess={
                                                handleUploadSuccess
                                            }
                                            onProgress={handleProgress}
                                        />
                                    </label>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className="d-flex justify-content-center align-items-center mt-3 px-4"
                                >
                                    {imageUrl && (
                                        <img
                                            src={imageUrl}
                                            alt="imagen temporal"
                                            className="form-image"
                                        />
                                    )}
                                </Grid>
                            </Grid>

                            <InputSubmit
                                type="submit"
                                value="Crear"
                                style={{ marginTop: '1rem' }}
                            />

                            {/* {error && <Error>{error}</Error>} */}
                            {errors.name && <Error>{errors.name}</Error>}
                            {errors.category && (
                                <Error>{errors.category}</Error>
                            )}
                            {errors.description && (
                                <Error>{errors.description}</Error>
                            )}
                            {errors.image && <Error>{errors.image}</Error>}
                        </Formulario>
                    </>
                )}
            </>
        </Container>
    );
}
