import React from 'react';

import Button from 'react-bootstrap/Button';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const UserListTable = ({
    active,
    email,
    id,
    deleteSuscription,
    name,
    phone,
    deleteUser,
}) => {
    return (
        <tr>
            <td className="align-middle">{email && email}</td>
            <td className="align-middle">{name && name}</td>
            <td className="align-middle">{phone && phone}</td>
            <td className="align-middle">
                {active ? (
                    <span className="btn-success p-2">Activo</span>
                ) : (
                    <span className="btn-danger p-2">No activo</span>
                )}
            </td>
            <td className="d-flex justify-content-center">
                <Button
                    variant="outline-dark"
                    className="my-2"
                    onClick={() => deleteSuscription(id, active)}
                >
                    <AutorenewIcon fontSize="small" />
                    <small>Suscripción</small>
                </Button>
                <Button
                    variant="outline-danger"
                    className="ml-2 my-2 p-2"
                    onClick={() => deleteUser(id)}
                >
                    <DeleteForeverIcon fontSize="small" />
                </Button>
            </td>
        </tr>
    );
};

export default UserListTable;
