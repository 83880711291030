import React from 'react';
import formatDistanceToNow from "date-fns/formatDistanceToNow"
import { es } from "date-fns/locale"
import { Link } from "react-router-dom"

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        borderRadius: 0
    },
    content: {
        padding: 10,
    },
    media: {
        height: 70,
        width: 90,
        objectFit: 'cover',
    },
    textTime: {
        fontSize: '0.8rem'
    },
});

const VideoAside = ({ video }) => {


    const classes = useStyles();
    const videoName = video.name.replace(/ /g, "-").toLowerCase()

    return (
        <Grid item xs={12} sm={6} md={12}>
            <Card className={classes.root} elevation={0}>

                <Link to={`/clases/${video.teacherID}/${videoName}?v=${video.id}`} >

                    <Grid container className="d-flex align-items-center teacher-list-box" spacing={3}>
                        <Grid item xs={4}>
                            <CardMedia
                                className={classes.media}
                                image={video.imageUrl}
                                title={video.name}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <CardContent className={classes.content}>
                                <Typography variant="h6" component="h6" style={{ lineHeight: 1.25, fontSize: '.9rem' }}>
                                    {video.name}
                                </Typography>
                                <Typography className={classes.textTime}>
                                    hace: {formatDistanceToNow(new Date(video.createdAt), { locale: es })}
                                </Typography>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Link>
            </Card>
        </Grid>

    );
}

export default VideoAside;