import React, { useEffect, useContext, useState } from 'react';
import { FirebaseContext } from '../../../../context/Firebase.context';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import { Formulario } from '../../../ui/Formulario';
import InputAdornment from '@material-ui/core/InputAdornment';

const OpcionesSuscripcion = () => {
    const { firebase, subcriptionSettings, setSubcriptionSettings } =
        useContext(FirebaseContext);
    const [price, setPrice] = useState(0);
    const [date, setDate] = useState(null);

    useEffect(() => {
        console.log('ey');
        if (subcriptionSettings) {
            setDate(subcriptionSettings.validUntil);
            setPrice(subcriptionSettings.price);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateSettings = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Los cambios pueden tener consecuencias en las ventas, asegurate de introducir los datos correctos',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, guardar cambios',
        }).then((result) => {
            if (result.value) {
                setSubcriptionSettings({
                    ...subcriptionSettings,
                    validUntil: date,
                    price: price,
                });
                firebase.db.collection('settings').doc('options').update({
                    validUntil: date,
                    price: price,
                });
                Swal.fire(
                    'Datos guardados',
                    'Los cambios se han guardado correctamente',
                    'success'
                );
            }
        });
    };
    const handleChange = (e) => {
        setPrice(e.target.value);
    };

    const handleChangeDate = (elm) => {
        setDate(Date.parse(elm));
    };

    return (
        <>
            <Container className="my-5">
                <h1 className="text-center">Ajustes de suscripción</h1>
                <Formulario>
                    <h5 className="text-center">
                        Precio y fecha de validez de las suscripciones
                    </h5>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                margin="normal"
                                label="Precio de las suscripciones"
                                type="number"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            €
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                value={price}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                selected={date}
                                onChange={handleChangeDate}
                                dateFormat="dd/MM/yyyy"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        className="my-4 py-3 px-5 float-right"
                        variant="outline-dark"
                        onClick={updateSettings}
                    >
                        Guardar cambios
                    </Button>
                </Formulario>
            </Container>
        </>
    );
};

export default OpcionesSuscripcion;
