import React from 'react';
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container"

const Error404 = () => {
    return (
        <Container>
            <h1 className="my-5 text-center"
            >Contenido solo para miembros de RondaFit</h1>
            <h3>Suscríbete ahora y accede a multitud de contenido, así como nuevos videos semanales.</h3>
            <div className="d-flex justify-content-around mb-5">
                <Link to="/" ><h2>Volver</h2></Link> <Link to="/suscripcion"><h2>Suscríbete ahora</h2></Link>
            </div>
            <div className="d-flex justify-content-center">
                <img src="/img/RF-Logo-verde.png" alt="RondaFit logo verde" />
            </div>
        </Container>
    )
}

export default Error404;