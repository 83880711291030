export default function validateCreateAccount(values) {

    let errors = {}



    if (!values.name) {
        errors.name = "El nombre es obligatorio";
    }

    // if (!values.videoID) {
    //     errors.videoID = "La url del video es obligatoria";
    // }

    if (!values.description) {
        errors.description = "La descripción es obligatoria";
    }



    return errors

}